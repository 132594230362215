import React from "react";
import imgRoadmap from "../../assets/images/icons-new-layout/lines-roadmap.png";
import COLORS from "../../utils/constants/colors";

const RoadmapImage = ({ label, semester, year }) => (
  <div className="roadmap-image-container d-flex flex-column align-items-start">
    <div className="roadmap-label mb-3">
      <span style={{ color: COLORS.gray900 }}>{`Q${semester}`}</span>{" "}
      <span style={{ color: COLORS.gray400 }}>{year ? year : ""}</span>
    </div>
    <div className="position-relative">
      <img src={imgRoadmap} alt="Roadmap" />
    </div>
  </div>
);

const CardRoadmap = ({ card, imagesCount, cardCount, year, semesters }) => {
  return (
    <div className="card-roadmap-container">
      {Array.from({ length: cardCount }).map((_, index) => (
        <React.Fragment key={index}>{card}</React.Fragment>
      ))}
      {semesters &&
        semesters
          .slice(0, imagesCount)
          .map((semester, index) => (
            <RoadmapImage
              key={index}
              semester={semester}
              year={year}
            />
          ))}
    </div>
  );
};

export default CardRoadmap;
