import { useSelector } from "react-redux";
import BREAKPOINTS from "../../utils/constants/breakpoints";
import UseTranslation from "../../hooks/useTranslation";
import { LandingPageI18n } from "../PagesComponents/LandingPage/LandingPageTranslactions";
import groupDots from "../../assets/images/icons-new-layout/groupDots.svg";
import COLORS from "../../utils/constants/colors";
import TitleLandingPage from "../PagesComponents/LandingPage/TitleSectionLandingPage";

const CardLandingPageSectionFive = () => {
    const state = useSelector(state => state);
    const { t } = UseTranslation(LandingPageI18n);

    const breakpointLg = state?.configsGeneralReducer?.windowWidth < BREAKPOINTS.LG;
    return ( 
        <div
          className="d-flex justify-content-center align-items-start align-items-lg-center position-relative overflow-hidden"
          style={{
            background: COLORS.gray900,
            borderRadius: 40,
            height: breakpointLg ? 420 : 580,
            padding: "40px 24px",
            width: "100%",
          }}
        >
          <img
            src={groupDots}
            alt=""
            style={{
              position: "absolute",
              top: breakpointLg ? "50%" : 110,
              right: breakpointLg ? 60 : 0,
              zIndex: 0,
              transform: breakpointLg && "translateY(-50%)",
            }}
          />
          {!breakpointLg && (
            <img
              src={groupDots}
              alt=""
              style={{
                position: "absolute",
                top: breakpointLg ? "50%" : 15,
                left: 0,
                zIndex: 0,
                transform: breakpointLg && "translateY(-50%)",
              }}
            />
          )}

          <div
            style={{
              border: `1px solid ${COLORS.gray700}`,
              position: "absolute",
              top: breakpointLg ? 308 : 74,
              width: 1910,
              height: 1910,
              borderRadius: "50%",
              background:
                "linear-gradient(0deg, rgba(255, 255, 255, 0) 85%, rgba(255, 255, 255, 0.06))",
              zIndex: 1,
              backdropFilter: "blur(5px)",
            }}
          ></div>
          <div
            style={{
              border: `1px solid ${COLORS.gray700}`,
              position: "absolute",
              top: breakpointLg ? 366 : 405,
              width: 1288,
              height: 1288,
              borderRadius: "50%",
              background:
                "linear-gradient(0deg, rgba(255, 255, 255, 0) 85%, rgba(255, 255, 255, 0.06))",
              backdropFilter: "blur(5px)",
              zIndex: 2,
            }}
          ></div>

          <TitleLandingPage
          styleDescription={{ width: "500px !important" }}
            lightMode={true}
            mediumFont
            gap={breakpointLg ? 12 : 24}
            alignItems={"center"}
            textAlign={"center"}
            headerWidth={100}
            widthTitle={670}
            border={`1px solid ${COLORS.gray200}`}
            borderRadius={100}
            title={t("sectionFive.title")}
            description={t("sectionFive.description")}
            showButton={true}
            buttonStyle={{
              background: COLORS.white,
              color: COLORS.gray900,
              marginTop: 20,
            }}
            buttonPadding={"8px 20px"}
          />
        </div>
     );
}
 
export default CardLandingPageSectionFive;