export const RoadmapTranslationsI18n = {
  "pt-BR": {
    journeyHeader: "Viagem",
    journeyTitle: "A viagem da Aizzy",
    journeyDescription: "Descubra os marcos que moldaram a evolução da Aizzy",
    title: "O que vem a seguir na Aizzy? Confira a rota",
    header: "Roteiro",
    cardOne: {
      title: "Aizzy Descentralizada",
      description: "Construindo privacidade e autonomia no aplicativo web",
      status: "Concluído",
    },
    cardTwo: {
      title: "Mercado de Agentes de IA",
      description: "Crie e venda agentes no mercado da Aizzy",
      status: "Em Progresso",
    },
    cardThree: {
      title: "Aizzy Mobile",
      description: "Levando a Aizzy para uma experiência nativa no celular",
      status: "Em Backlog",
    },
    milestones: [
      {
        start: "MAI 2023",
        end: "JUN 2023",
        title: "Começando do zero",
        description: "Aizzy está online, com um tema comprado e chatbot apenas",
      },
      {
        start: "AGO 2023",
        end: "SET 2023",
        title: "Recursos do assistente",
        description: "Aizzy recebe o primeiro assistente e desenvolve a funcionalidade RAG",
      },
      {
        start: "OUT 2023",
        end: "DEZ 2023",
        title: "Ficando viral",
        description: "Aizzy se torna viral com +300.000 usuários na plataforma. Paralelamente, a OpenAI lança recursos que a Aizzy já tinha",
      },
      {
        start: "FEV 2024",
        end: "ABR 2024",
        title: "A nova estratégia",
        description: "Aizzy teve que repensar a estratégia de sobrevivência. Também começou a focar em clientes B2B.",
      },
      {
        start: "MAI 2024",
        end: "JUN 2024",
        title: "Fundação da descentralização",
        description: "Aizzy muda os sócios. Também trabalha no modelo de descentralização.",
      },
      {
        start: "OUT 2024",
        end: "DEZ 2024",
        title: "Descentralização & Estabilidade",
        description: "Aizzy muda os sócios. Também trabalha no modelo de descentralização.",
      },
    ],
  },
  "en-US": {
    journeyHeader: "Journey",
    journeyTitle: "The Aizzy journey",
    journeyDescription: "Discover the milestones shaping Aizzy's evolution",
    title: "What's next in Aizzy? Check the roadmap",
    header: "Roadmap",
    cardOne: {
      title: "Decentralized Aizzy",
      description: "Building privacy & autonomy in the web-app",
      status: "Completed",
    },
    cardTwo: {
      title: "AI Agents Marketplace",
      description: "Create and sell agents in the Aizzy marketplace",
      status: "In Progress",
    },
    cardThree: {
      title: "Aizzy Mobile",
      description: "Bringing Aizzy to a mobile native experience",
      status: "Backlog",
    },
    milestones: [
      {
        start: "MAI 2023",
        end: "JUN 2023",
        title: "Starting from bottom",
        description: "Aizzy is online, with a bought theme and chat bot only",
      },
      {
        start: "AUG 2023",
        end: "SEP 2023",
        title: "Assistant features",
        description: "Aizzy gets the first assistant, and develops the RAG feature",
      },
      {
        start: "OCT 2023",
        end: "DEC 2023",
        title: "Getting viral",
        description: "Aizzy goes viral with +300,000 users on the platform. In parallel, OpenAI releases features Aizzy had",
      },
      {
        start: "FEB 2024",
        end: "APR 2024",
        title: "The new strategy",
        description: "Aizzy had to rethink the survival strategy. Also start focusing on B2B clients.",
      },
      {
        start: "MAY 2024",
        end: "JUN 2024",
        title: "Founding decentralization",
        description: "Aizzy changes the associates. Also works on the decentralization model.",
      },
      {
        start: "OCT 2024",
        end: "DEC 2024",
        title: "Decentralization & Stability",
        description: "Aizzy changes the associates. Also works on the decentralization model.",
      },
    ],
  },
  "es-ES": {
    journeyHeader: "Viaje",
    journeyTitle: "El viaje de Aizzy",
    journeyDescription: "Descubre los marcos que moldaron la evolución de Aizzy",
    title: "Que viene a seguir en Aizzy? ¡Mira la ruta!",
    header: "Ruta",
    cardOne: {
      title: "Aizzy Descentralizado",
      description: "Construyendo privacidad y autonomía en la aplicación web",
      status: "Completado",
    },
    cardTwo: {
      title: "Mercado de Agentes de IA",
      description: "Crea y vende agentes en el mercado de Aizzy",
      status: "En Progreso",
    },
    cardThree: {
      title: "Aizzy Mobile",
      description: "Llevando Aizzy a una experiencia nativa móvil",
      status: "En Lista de Espera",
    },
    milestones: [
      {
        start: "MAYO 2023",
        end: "JUN 2023",
        title: "Comenzando desde abajo",
        description: "Aizzy está en línea, con un tema comprado y solo un chatbot",
      },
      {
        start: "AGO 2023",
        end: "SEP 2023",
        title: "Características del asistente",
        description: "Aizzy recibe el primer asistente y desarrolla la funcionalidad RAG",
      },
      {
        start: "OCT 2023",
        end: "DIC 2023",
        title: "Haciéndose viral",
        description: "Aizzy se vuelve viral con más de 300,000 usuarios en la plataforma. Paralelamente, OpenAI lanza características que Aizzy ya tenía",
      },
      {
        start: "FEB 2024",
        end: "ABR 2024",
        title: "La nueva estrategia",
        description: "Aizzy tuvo que repensar la estrategia de supervivencia. También comenzó a centrarse en clientes B2B.",
      },
      {
        start: "MAYO 2024",
        end: "JUN 2024",
        title: "Fundación de descentralización",
        description: "Aizzy cambia los socios. También trabaja en el modelo de descentralización.",
      },
      {
        start: "OCT 2024",
        end: "DIC 2024",
        title: "Descentralización & Estabilidad",
        description: "Aizzy cambia los socios. También trabaja en el modelo de descentralización.",
      },
    ],
  },
};
