import { useSelector } from "react-redux";
import COLORS from "../../../utils/constants/colors";
import ButtonType1 from "../../Buttons/ButtonType1";
import BREAKPOINTS from "../../../utils/constants/breakpoints";
import { LandingPageI18n } from "./LandingPageTranslactions";
import UseTranslation from "../../../hooks/useTranslation";
import { useNavigate } from "react-router-dom";

const TitleLandingPage = ({
  border,
  background,
  headerText,
  title,
  widthTitle,
  description,
  showButton,
  headerWidth,
  borderRadius,
  buttonPadding,
  justifyContent,
  alignItems,
  textAlign,
  gap,
  style: customStyle,
  buttonStyle: customButtonStyle,
  largeFont = false,
  darkMode = false,
  lightMode = false,
  mediumFont = false,
  styleMain,
  styleDescription,
  textButton,
}) => {
  const state = useSelector(state => state);
  const navigate = useNavigate();

  const { t } = UseTranslation(LandingPageI18n);

  const lg = state?.configsGeneralReducer?.windowWidth < BREAKPOINTS.LG;

  return (
    <div
      className="d-flex flex-column justify-content-center gap-4"
      style={{ zIndex: 5, alignItems, ...styleMain }}
    >
      {headerText && (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            border,
            width: headerWidth,
            height: 24,
            borderRadius,
            fontSize: 12,
            background,
            ...customStyle,
          }}
        >
          {headerText}
        </div>
      )}
      <div
        className="d-flex flex-column align-items-center"
        style={{ justifyContent, gap }}
      >
        {typeof title === "string" ? (
          <h1
            className={`${
              largeFont
                ? "title-large"
                : mediumFont
                ? "title-medium"
                : "title-small"
            }`}
            style={{
              maxWidth: widthTitle,
              color: darkMode ? COLORS.gray700 : COLORS.white,
              width: "100%",
              textAlign,
            }}
          >
            {title}
          </h1>
        ) : (
          title
        )}

        <div
          style={{
            maxWidth: 400,
            width: "100%",
            display: "flex",
            ...styleDescription,
          }}
        >
          <h5
            style={{
              color: lightMode ? COLORS.gray400 : COLORS.gray700,
              textAlign: textAlign,
              fontSize: lg ? 14 : 16,
            }}
          >
            {description}
          </h5>
        </div>
        {showButton && (
          <ButtonType1
            text={ textButton ? textButton : t("button")}
            props={{
              onClick: () => window.location.href = process.env.REACT_APP_PUBLIC_SITE_URL,
              disabled: false,
              style: {
                ...customButtonStyle,
                padding: buttonPadding,
                borderRadius: 12,
              },
            }}
          />
        )}
      </div>
    </div>
  );
};

export default TitleLandingPage;
