import { Link, useLocation, useNavigate } from "react-router-dom";
import COLORS from "../../../utils/constants/colors";
import { AizzyLogo } from "../../AizzyLogo";
import ButtonType1 from "../../Buttons/ButtonType1";
import { IconX } from "@tabler/icons-react";
import { LandingPageI18n } from "./LandingPageTranslactions";
import UseTranslation from "../../../hooks/useTranslation";
import SocialMideas from "../../../utils/constants/SocialMedia";
import { Select } from "antd";

const MenuMobile = ({
  openMenuMobile,
  breakpointLg,
  state,
  language,
  languageOptions,
  handleLanguageChange,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = UseTranslation(LandingPageI18n);

  const handleNavigationClick = sectionId => {
    if (location.pathname === "/") {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      navigate("/");
      setTimeout(() => {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
        }
      }, 500);
    }
    openMenuMobile(false);
  };

  const navigationLinks = [
    {
      title: t("nav.about"),
      onClick:
        location.pathname === "/about"
          ? null
          : () => handleNavigationClick("about"),
      disabled: location.pathname === "/maintenance",
    },
    {
      title: t("nav.capabilities"),
      onClick:
        location.pathname === "/maintenance"
          ? null
          : () => handleNavigationClick("capabilities"),
      disabled: location.pathname === "/maintenance",
    },
    {
      title: "FAQ",
      onClick:
        location.pathname === "/maintenance"
          ? null
          : () => handleNavigationClick("faqs"),
      disabled: location.pathname === "/maintenance",
    },
    {
      title: "Roadmap",
      link: "/roadmap",
      disabled: location.pathname === "/maintenance",
    },
  ];

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100vh",
          zIndex: 888,
          backgroundColor: "#ffffff",
          opacity: 0.6,
        }}
      ></div>
      <div
        className="d-flex flex-column align-items-center p-3"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          maxHeight: 600,
          height: "calc(100vh - 200px)",
          zIndex: 999,
          backgroundColor: COLORS.white,
        }}
      >
        <div
          className="w-100 h-100 d-flex align-items-center flex-column gap-4 rounded-3"
          style={{
            borderBottom: `1px solid ${COLORS.gray100}`,
            boxShadow: `
             0px 0px 0px 4px rgba(242, 244, 247, 1),
                0px 1px 2px 0px rgba(16, 24, 40, 0.05)`,
            padding: "16px 16px 16px 24px",
          }}
        >
          <nav
            className="w-100 d-flex justify-content-between align-items-center"
            style={{ marginTop: 12, padding: "5px 9px 0 0" }}
          >
            <div
              className="cursor-pointer"
              onClick={() =>
                location.pathname !== "/maintenance" && navigate("/")
              }
            >
              <AizzyLogo width={83} height={24} />
            </div>

            <div className="d-flex gap-2">
              <ButtonType1
                text={t("button")}
                props={{
                  onClick: () => navigate("/app"),
                  disabled: location.pathname === "/maintenance" ? true : false,
                  style: {
                    borderRadius: "8px",
                    padding: "4px 12px",
                  },
                }}
              />
              <ButtonType1
                props={{
                  disabled: false,
                  onClick: openMenuMobile,
                  style: {
                    borderRadius: "10px",
                    background: COLORS.gray100,
                    width: 32,
                    height: 32,
                  },
                }}
                variation="secondary"
                text={<IconX size={16} stroke={1.5} />}
              />
            </div>
          </nav>
          <div></div>
          <div
            className="w-100 nav-landing-page"
            style={{ overflowY: "auto", maxHeight: 550 }}
          >
            <ul className="w-100 d-flex flex-column gap-2 nav-links">
              {breakpointLg && (
                <Select
                  className="select-language-antd"
                  style={{
                    height: 33,
                    minWidth: 85,
                    maxWidth: 85,
                    fontSize: 14,
                    padding: 0,
                  }}
                  value={
                    language || state?.configsGeneralReducer?.currentLanguage
                  }
                  onChange={handleLanguageChange}
                >
                  {languageOptions.map(lang => (
                    <Select.Option
                      key={lang.code}
                      value={lang.name}
                      label={
                        <img
                          src={lang.flag}
                          alt={lang.name}
                          style={{ width: 20, height: 14 }}
                        />
                      }
                    >
                      <div className="d-flex align-items-center align-self-start">
                        <img
                          src={lang.flag}
                          alt={lang.name}
                          style={{ width: 18, height: 14, marginRight: 8 }}
                        />
                        <span className="font-size-14">{lang.name}</span>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              )}
              {navigationLinks.map((item, index) => (
                <li
                  key={index}
                  className={`d-flex align-items-center nav-item `}
                  style={{
                    height: 44,
                    color: item.disabled ? COLORS.gray300 : COLORS.gray500,
                  }}
                  onClick={
                    item.disabled ? e => e.preventDefault() : item.onClick
                  }
                >
                  {item.link ? (
                    <Link
                      to={item.link}
                      target={item.link.startsWith("http") ? "_blank" : "_self"}
                      style={{
                        textDecoration: "none",
                        color: item.disabled ? COLORS.gray300 : COLORS.gray500,
                        pointerEvents: item.disabled ? "none" : "auto",
                      }}
                      onClick={item.disabled ? e => e.preventDefault() : null}
                      className="nav-link"
                    >
                      {item.title}
                    </Link>
                  ) : (
                    <button
                      style={{
                        background: "none",
                        border: "none",
                        color: item.disabled ? COLORS.gray300 : COLORS.gray500,
                        cursor: item.disabled ? "default" : "pointer",
                      }}
                      className={
                        location.pathname === "/maintenance" ? "" : "nav-link"
                      }
                      onClick={
                        item.disabled ? e => e.preventDefault() : item.onClick
                      }
                    >
                      {item.title}
                    </button>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div
            className="d-flex justify-content-between w-100 flex-grow-1 align-items-end"
            style={{ color: COLORS.gray400 }}
          >
            <span className="font-size-12">
              © 2024 Aizzy. All rights reserved.
            </span>
            <ul className="d-flex gap-2">
              {SocialMideas.map((social, index) => (
                <li key={index}>
                  <Link
                    to={social.link}
                    target="_blank"
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      width: 24,
                      height: 24,
                      borderRadius: 6,
                      background: COLORS.gray100,
                    }}
                  >
                    <img
                      src={social.image}
                      alt={social.name}
                      width={16}
                      height={16}
                    />
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default MenuMobile;
