import TitleLandingPage from "./TitleSectionLandingPage";
import BREAKPOINTS from "../../../utils/constants/breakpoints";
import { useSelector } from "react-redux";
import Faqs from "./Faqs";
import { IconArrowRight } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { useLayoutEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const SectionFaqs = () => {
  const state = useSelector(state => state);

  const breakpointLg =
    state?.configsGeneralReducer?.windowWidth < BREAKPOINTS.LG;

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.fromTo(
      ".title-section-faqs",
      {
        x: -300,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: ".section-faqs",
          start: "top 80%",
          end: breakpointLg ? "bottom 120%" : "bottom 75%",
          scrub: true,
          scroller: ".main-landing-page",
        },
      }
    );

    // Cleanup
    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);

  return (
    <section
      id="faqs"
      className="w-100 d-flex justify-content-center align-items-center section-faqs"
    >
      <div
        className=" d-flex flex-lg-row justify-content-between flex-column gap-5"
        style={{
          width: "100%",
          maxWidth: 1440,
          padding: breakpointLg ? "110px 16px" : "120px 80px 80px 80px",
        }}
      >
        <div className="title-section-faqs">
          <TitleLandingPage
            darkMode
            mediumFont
            alignItems={"start"}
            textAlign={"start"}
            headerWidth={100}
            widthTitle={370}
            justifyContent={"start"}
            borderRadius={100}
            title={"Frequently Asked Questions"}
            description={"Need custom help? Reach our support"}
            buttonPadding={"8px 20px"}
            styleDescription={{ marginTop: "16px" }}
          />
          <div className="d-flex align-items-center gap-2">
            <u
              className="cursor-pointer"
              style={{ fontSize: breakpointLg ? "14px" : "16px" }}
            >
              <Link to="https://t.me/aizzyai" target="_blank">
                Contact us
              </Link>
            </u>
            <IconArrowRight size={16} stroke={1.5} />
          </div>
        </div>
        <Faqs />
      </div>
    </section>
  );
};

export default SectionFaqs;
