import COLORS from "../../../utils/constants/colors";
import TitleLandingPage from "../LandingPage/TitleSectionLandingPage";
import { useSelector } from "react-redux";
import BREAKPOINTS from "../../../utils/constants/breakpoints";
import UseTranslation from "../../../hooks/useTranslation";
import { LandingPageI18n } from "./LandingPageTranslactions";
import { useLayoutEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import CardLandingPageSectionFive from "../../Cards/CardLandingPageSectionFive";

const SectionFiveLandingPage = () => {
  const state = useSelector(state => state);

  const { t } = UseTranslation(LandingPageI18n);

  const breakpointLg =
    state?.configsGeneralReducer?.windowWidth < BREAKPOINTS.LG;

  useLayoutEffect(() => {
    const scroller = document.querySelector(".main-landing-page") || window;

    gsap.registerPlugin(ScrollTrigger);

    requestAnimationFrame(() => {
      ScrollTrigger.defaults({
        scroller,
      });

      gsap.fromTo(
        ".section-five",
        { scale: 0.5, opacity: 0 },
        {
          scale: 1,
          opacity: 1,
          scrollTrigger: {
            trigger: ".section-five",
            start: "top 100%",
            end: "bottom 70%",
            scrub: true,
            scroller: ".main-landing-page",
          },
        }
      );
    });

    // Cleanup
    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);

  return (
    <section className="d-flex justify-content-center align-items-center section-five">
      <div
        className="w-100 d-flex justify-content-center"
        style={{
          padding: breakpointLg ? "80px 16px" : "80px 80px",
          maxWidth: 1440,
        }}
      >
        <CardLandingPageSectionFive
          title={
            <TitleLandingPage
              lightMode={true}
              mediumFont
              gap={breakpointLg ? 12 : 24}
              alignItems={"center"}
              textAlign={"center"}
              headerWidth={100}
              widthTitle={670}
              border={`1px solid ${COLORS.gray200}`}
              borderRadius={100}
              title={t("sectionFive.title")}
              description={t("sectionFive.description")}
              showButton={true}
              buttonStyle={{
                background: COLORS.white,
                color: COLORS.gray900,
                marginTop: 20,
              }}
              buttonPadding={"8px 20px"}
            />
          }
        />
      </div>
    </section>
  );
};

export default SectionFiveLandingPage;
