/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import {
  IconBolt,
  IconLoader2,
  IconMessage2,
  IconPlus,
  IconRobotFace,
  IconSearch,
  IconTrash,
} from "@tabler/icons-react";
import GroupListChats from "./GroupListChats";
import {
  useCreateNewChannel,
  useGetConfigsMainChat,
  useGetIsLogged,
} from "../../../../hooks/smallHooks";
import { useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { QUERY_KEYS } from "../../../../libs/ReactQuery";
import { getChannelsAndDocuments } from "../../../../api";
import { useDispatch, useSelector } from "react-redux";
import {
  setConfigsMainChat,
  setOpenLeftBar,
  setOpenLeftBarMobile,
  setOpenLogin,
} from "../../../../redux/general/action";
import BREAKPOINTS from "../../../../utils/constants/breakpoints";
import {
  endOfDay,
  isAfter,
  isBefore,
  isToday,
  startOfDay,
  subDays,
} from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonAsideType1 from "../../../Buttons/ButtonAsideType1";
import { AizzyLogo } from "../../../AizzyLogo";
import { AizzySmallLogo } from "../../../AizzySmallLogo";
import UseTranslation from "../../../../hooks/useTranslation";
import { SideMenuType2I18n } from "./SideMenuType2.i18n";
import { useChatStore } from "../../../../state/chat";
import { showRealoadNotification } from "../../../../services/notifications";
import COLORS from "../../../../utils/constants/colors";
import ButtonOpenLeftBar from "../../../Buttons/ButtonOpenLeftBar";
import UseClickOutside from "../../../../utils/UseClickOutside";
import CardFooterSideLeftBar from "../../../Cards/CardFooterSideLeftBar";
import { KEYS_MAIN_CHAT } from "../../../../services/chat/defaultConfigsMainChat";
import UseDeleteAllChats from "../../../../utils/deleteAllChats";

const SideMenuType2 = () => {
  let showError = false;
  const { handleCreateChannel, isCreating } = useCreateNewChannel();
  const state = useSelector(state => state);
  const dispatch = useDispatch();
  const [searchChats, setSearchChats] = useState("");
  const [loading, setLoading] = useState(false);
  const [dropDownShow, setDropDownShow] = useState(false);
  const [showInput, setShowInput] = useState(true);
  const [icon, setIcons] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = UseTranslation(SideMenuType2I18n);
  const configsMainChat = useGetConfigsMainChat();

  const { handleDeleteAllChats } = UseDeleteAllChats();
  const { reset } = useChatStore();

  const isMobile = state?.configsGeneralReducer?.windowWidth < BREAKPOINTS.LG;

  const logged = useGetIsLogged();

  const {
    data: channels,
    isLoading,
    error,
  } = useQuery(
    QUERY_KEYS.CHANNELS,
    async () => {
      const data = await getChannelsAndDocuments();
      return data.channels ?? [];
    },
    {
      staleTime: 180 * 60 * 1000,
      enabled: logged,
    }
  );

  const channelDetails = useMemo(() => {
    if (!Array.isArray(channels)) return [];

    return (
      channels &&
      channels.map(
        ({
          _id,
          userId,
          channelName,
          assistantId,
          created_at,
          updated_at,
        }) => ({
          _id,
          userId,
          channelName,
          assistantId,
          createdAt: created_at,
          updatedAt: updated_at,
        })
      )
    );
  }, [channels]);

  const filteredChannels = useMemo(() => {
    if (searchChats.length > 0) {
      return channelDetails.filter(chat =>
        chat.channelName.toLowerCase().includes(searchChats.toLowerCase())
      );
    }
    return channelDetails;
  }, [searchChats, channelDetails]);

  const groupedChats = useMemo(() => {
    const now = new Date();
    const startOfToday = startOfDay(now);
    const startOfYesterday = startOfDay(subDays(now, 1));
    const endOfLast7Days = endOfDay(subDays(now, 7));
    const startOfLast30Days = startOfDay(subDays(now, 30));

    const todayChats = channelDetails.filter(chat =>
      isToday(new Date(chat.createdAt))
    );

    const yesterdayChats = channelDetails.filter(
      chat =>
        isAfter(new Date(chat.createdAt), startOfYesterday) &&
        isBefore(new Date(chat.createdAt), startOfToday)
    );

    const last7DaysChats = channelDetails.filter(
      chat =>
        isAfter(new Date(chat.createdAt), endOfLast7Days) &&
        isBefore(new Date(chat.createdAt), startOfYesterday)
    );

    const last30DaysChats = channelDetails.filter(
      chat =>
        isAfter(new Date(chat.createdAt), startOfLast30Days) &&
        isBefore(new Date(chat.createdAt), endOfLast7Days)
    );

    const previousChats = channelDetails.filter(chat =>
      isBefore(new Date(chat.createdAt), startOfLast30Days)
    );

    return {
      todayChats,
      yesterdayChats,
      last7DaysChats,
      last30DaysChats,
      previousChats,
    };
  }, [channelDetails]);

  useEffect(() => {
    if (state.configsGeneralReducer.windowWidth < BREAKPOINTS.LG) {
      dispatch(setOpenLeftBar(true));
    }
  }, [dispatch, state.configsGeneralReducer.windowWidth]);

  const handleSidebarToggle = () => {
    if (isMobile) {
      dispatch(
        setOpenLeftBarMobile(!state?.configsGeneralReducer?.openLeftBarMobile)
      );
    } else {
      dispatch(setOpenLeftBar(!state?.configsGeneralReducer?.openLeftBar));
    }
  };

  const handleOutsideClick = event => {
    if (
      !event.target.closest(".side-menu-type-2") &&
      !event.target.closest(".menu-btn")
    ) {
      event.stopPropagation("menu-btn");
      dispatch(setOpenLeftBarMobile(false));
    }
  };

  const dropdownRef = useRef(null);
  const handleOutsideDropdown = () => {
    setTimeout(() => {
      setDropDownShow(false);
    }, 150);
  };

  UseClickOutside(dropdownRef, handleOutsideDropdown); // Usa o hook

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [dispatch]);

  const deleteChats = async () => {
    setLoading(true);
    await handleDeleteAllChats();
    reset();
    setLoading(false);
    setDropDownShow(false);
    isMobile && dispatch(setOpenLeftBarMobile(false));
  };

  useEffect(() => {
    showRealoadNotification(error ? error?.message : null, showError);
    showError = true;
  }, [error]);

  const groupChats =
    groupedChats.todayChats.length > 0 ||
    groupedChats.yesterdayChats.length > 0 ||
    groupedChats.last7DaysChats.length > 0 ||
    groupedChats.last30DaysChats.length > 0;

  const verifyDataCreateChannel = groupedChats => {
    const allChats = [
      ...groupedChats.todayChats,
      ...groupedChats.yesterdayChats,
      ...groupedChats.last7DaysChats,
      ...groupedChats.last30DaysChats,
    ];

    const latestChat = allChats.reduce((latest, current) => {
      return new Date(current.createdAt) > new Date(latest.createdAt)
        ? current
        : latest;
    }, allChats[0]);

    return latestChat;
  };

  function handleSelectChat(chat) {
    if (configsMainChat?.chatData?._id === chat._id) return;
    dispatch(setConfigsMainChat(KEYS_MAIN_CHAT.chatData, chat));
    reset();
  }

  return (
    <aside
      className={`side-menu-type-2 ${
        !state?.configsGeneralReducer?.openLeftBar ? "minimize" : ""
      } ${
        state?.configsGeneralReducer?.openLeftBarMobile === true
          ? "d-flex animate__animated animate__fadeInLeft animate__faster"
          : isMobile
          ? "d-flex animate__animated animate__fadeOutLeft animate__faster"
          : "d-flex"
      }`}
    >
      {!state?.configsGeneralReducer?.openLeftBar && (
        <button
          onClick={() => {
            navigate("/app");
            isMobile && dispatch(setOpenLeftBarMobile(false));
          }}
          className="pointer-event"
        >
          <AizzySmallLogo />
        </button>
      )}
      <div
        className="d-flex justify-content-between align-items-center"
        style={{
          marginBottom: state?.configsGeneralReducer?.openLeftBar ? 40 : 0,
        }}
      >
        {state?.configsGeneralReducer?.openLeftBar && (
          <button
            onClick={() => {
              navigate("/app");
              isMobile && dispatch(setOpenLeftBarMobile(false));
            }}
            className="pointer-event"
          >
            <AizzyLogo width={84} height={24} />
          </button>
        )}
        <ButtonOpenLeftBar
          minHeight={40}
          handleSidebarToggle={handleSidebarToggle}
          state={state}
          iconLeftPipe
          onClick={() => {
            handleSidebarToggle();
            setShowInput(false);
          }}
        />
      </div>

      {!state?.configsGeneralReducer?.openLeftBar && (
        <div className={"divider"}></div>
      )}
      <ButtonAsideType1
        dark
        onClick={async () => {
          if (logged) {
            isMobile && dispatch(setOpenLeftBarMobile(false));
            await handleCreateChannel();

            if (location.pathname !== "/app") {
              navigate("/app");
            }
          } else {
            isMobile && dispatch(setOpenLeftBarMobile(false));
            dispatch(setOpenLogin(true));
          }
        }}
        disabled={isCreating}
        props={{
          style: {
            justifyContent: state?.configsGeneralReducer?.openLeftBar
              ? "flex-start"
              : "center",
          },
        }}
      >
        {isCreating ? (
          <>
            <IconLoader2 stroke={1} size={16} className="spin" />
            <span className="one-line">Creating...</span>
          </>
        ) : (
          <>
            <IconPlus stroke={1.5} size={16} style={{ minWidth: 20 }} />
            {state?.configsGeneralReducer?.openLeftBar && (
              <span className="one-line font-size-14">
                {t("header.button")}
              </span>
            )}
          </>
        )}
      </ButtonAsideType1>
      <ButtonAsideType1
        props={{
          className: "d-flex",
          style: {
            gap: 12,
            background: COLORS.gray100,
            justifyContent: state?.configsGeneralReducer?.openLeftBar
              ? "flex-start"
              : "center",
          },
        }}
        onClick={() => {
          if (logged) {
            navigate("/assistants");
            dispatch(setOpenLeftBarMobile(false));
          } else {
            dispatch(setOpenLogin(true));
          }
        }}
      >
        <IconRobotFace stroke={1.8} size={16} style={{ minWidth: 20 }} />
        {state?.configsGeneralReducer?.openLeftBar && (
          <span
            className="one-line"
            style={{
              fontSize: 14,
            }}
          >
            {t("footer.assistants")}
          </span>
        )}
      </ButtonAsideType1>
      {state?.configsGeneralReducer?.openLeftBar && groupChats && (
        <div
          className="d-flex justify-content-between align-items-center position-relative cursor-pointer"
          style={{ padding: "0 12px", height: 25 }}
          onMouseEnter={() => {
            setIcons(true);
          }}
          onMouseLeave={() => {
            if (showInput || dropDownShow) {
              return;
            }
            setIcons(false);
          }}
        >
          <div className="d-flex align-items-center" style={{ gap: 8 }}>
            <IconMessage2 stroke={1.5} size={14} />
            <span
              className="one-line font-size-14"
              style={{ color: COLORS.gray900 }}
              onClick={() => {
                const lastChat = verifyDataCreateChannel(groupedChats);
                if (lastChat && !searchChats.length > 0) {
                  handleSelectChat(lastChat);
                  if (location.pathname !== "/") {
                    navigate("/");
                  }
                  isMobile && dispatch(setOpenLeftBarMobile(false));
                }
              }}
            >
              Chats
            </span>
          </div>
          <div className="d-flex align-items-center gap-2 animate__animated animate__fadeInDown animate__faster">
            <ButtonAsideType1
              props={{
                className:
                  "button-search-aside p-0 d-flex align-items-center justify-content-center",
              }}
              onClick={() => {
                if (showInput) {
                  setShowInput(false);
                  setSearchChats("");
                } else {
                  setShowInput(true);
                }
              }}
            >
              <IconSearch
                stroke={1.5}
                size={13}
                style={{ color: showInput ? COLORS.gray700 : COLORS.gray500 }}
              />
            </ButtonAsideType1>
            <ButtonOpenLeftBar
              ref={dropdownRef}
              state={state}
              onClick={() => {
                setDropDownShow(true);
              }}
              dropDown={dropDownShow}
            />
          </div>
          {dropDownShow && (
            <div
              ref={dropdownRef}
              className={`px-2 d-flex align-items-center dropdown-chats animate__animated animate__fadeInDown animate__faster ${
                loading
                  ? "d-flex justify-content-center"
                  : "d-flex justify-content-between"
              }`}
            >
              <ButtonAsideType1
                hover
                onClick={() => {
                  if (logged) {
                    deleteChats();
                  } else {
                    dispatch(setOpenLogin(true));
                  }
                }}
                props={{
                  className: `w-100 d-flex align-items-center ${
                    loading
                      ? "d-flex justify-content-center"
                      : "d-flex justify-content-between"
                  }`,
                }}
              >
                {loading ? (
                  <IconLoader2 stroke={1.8} size={16} className="spin" />
                ) : (
                  <>
                    {state?.configsGeneralReducer?.openLeftBar && (
                      <span
                        className="one-line"
                        style={{
                          fontSize: 14,
                          color: COLORS.gray700,
                        }}
                      >
                        {t("footer.deleteAllChats")}
                      </span>
                    )}
                    <IconTrash
                      stroke={1.5}
                      size={16}
                      style={{ width: 16, color: COLORS.gray700 }}
                    />
                  </>
                )}
              </ButtonAsideType1>
            </div>
          )}
        </div>
      )}
      {groupChats && showInput && state?.configsGeneralReducer?.openLeftBar && (
        <input
          className="animate__animated animate__fadeInDown animate__faster"
          type="text"
          name="search-chats"
          style={{
            border: `1.8px solid ${COLORS.gray200}`,
            padding: "5px 10px",
            outline: "none",
            borderRadius: 8,
          }}
          placeholder={t("header.search")}
          id="search-chats"
          onChange={e => setSearchChats(e.target.value)}
        />
      )}
      {isLoading ? (
        <div className="mt-4 w-100 d-flex justify-content-center">
          <IconLoader2 stroke={2} size={16} className="spin" />
        </div>
      ) : (
        state?.configsGeneralReducer?.openLeftBar && (
          <>
            {searchChats.length > 0 ? (
              <GroupListChats
                title={t("header.searchResults")}
                chats={filteredChannels}
              />
            ) : (
              <div className="d-flex flex-column gap-2 list-chats">
                {groupedChats.todayChats.length > 0 && (
                  <GroupListChats
                    title={
                      state?.configsGeneralReducer?.openLeftBar
                        ? t("groupList.today")
                        : null
                    }
                    chats={groupedChats.todayChats}
                  />
                )}
                {groupedChats.yesterdayChats.length > 0 && (
                  <GroupListChats
                    title={
                      state?.configsGeneralReducer?.openLeftBar
                        ? t("groupList.yesterday")
                        : null
                    }
                    chats={groupedChats.yesterdayChats}
                  />
                )}
                {groupedChats.last7DaysChats.length > 0 && (
                  <GroupListChats
                    title={
                      state?.configsGeneralReducer?.openLeftBar
                        ? t("groupList.lastDays")
                        : null
                    }
                    chats={groupedChats.last7DaysChats}
                  />
                )}
                {groupedChats.last30DaysChats.length > 0 && (
                  <GroupListChats
                    title={
                      state?.configsGeneralReducer?.openLeftBar
                        ? t("groupList.last30Days")
                        : null
                    }
                    chats={groupedChats.last30DaysChats}
                  />
                )}
                {groupedChats.previousChats.length > 0 && (
                  <GroupListChats
                    title={
                      state?.configsGeneralReducer?.openLeftBar
                        ? "Previous"
                        : null
                    }
                    chats={groupedChats.previousChats}
                  />
                )}
              </div>
            )}
          </>
        )
      )}
      {state?.configsGeneralReducer?.openLeftBar ? (
        logged && <CardFooterSideLeftBar t={t} />
      ) : (
        <ButtonAsideType1
          props={{
            className: "d-flex p-0 mt-auto",
            style: {
              gap: 12,
              background: COLORS.gray100,
              justifyContent: state?.configsGeneralReducer?.openLeftBar
                ? "flex-start"
                : "center",
            },
          }}
          onClick={() => {
            if (logged) {
              navigate("/settings?tab=credits");
            } else {
              dispatch(setOpenLogin(true));
            }
          }}
        >
          <IconBolt stroke={1.5} size={16} />
        </ButtonAsideType1>
      )}
    </aside>
  );
};

export default SideMenuType2;
