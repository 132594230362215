import React from "react";
import { RoadmapTranslationsI18n } from "./RoadmapTranslations.i18n";
import UseTranslation from "../../../hooks/useTranslation";

const Timeline = () => {
    const { t } = UseTranslation(RoadmapTranslationsI18n);
  const timelineData = [
    {
      start: t("milestones.0.start"),
      end: t("milestones.0.end"),
      title: t("milestones.0.title"),
      description: t("milestones.0.description"),
    },
    {
      start: t("milestones.1.start"),
      end: t("milestones.1.end"),
      title: t("milestones.1.title"),
      description: t("milestones.1.description"),
    },
    {
      start: t("milestones.2.start"),
      end: t("milestones.2.end"),
      title: t("milestones.2.title"),
      description: t("milestones.2.description"),
    },
    {
      start: t("milestones.3.start"),
      end: t("milestones.3.end"),
      title: t("milestones.3.title"),
      description: t("milestones.3.description"),
    },
    {
      start: t("milestones.4.start"),
      end: t("milestones.4.end"),
      title: t("milestones.4.title"),
      description: t("milestones.4.description"),
    },
    {
      start: t("milestones.5.start"),
      end: t("milestones.5.end"),
      title: t("milestones.5.title"),
      description: t("milestones.5.description"),
    },
  ];

  return (
    <div className="timeline">
      {timelineData.map((item, index) => (
        <div className="timeline-item" key={index}>
          <div className="timeline-date">
            <span className="timeline-date-start">{item.start}</span>
            {"-"}
            <span className="timeline-date-end">{item.end}</span>
          </div>
          <div className="timeline-content">
            <h3 className="timeline-title">{item.title}</h3>
            <p className="timeline-description">{item.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Timeline;
