import iconMetaMask from "../../../assets/images/icons-new-layout/metamask.svg";
import UseTranslation from "../../../hooks/useTranslation";
import ButtonType1 from "../../Buttons/ButtonType1";
import ModalInstallMetamask from "../../Modals/ModalInstallMetamask";
import { useState } from "react";
import { GroupLoginI18n } from "../GroupLogin.i18n";

const Web3Login = ({ handleLoginWeb3 = () => {}, isLoading }) => {
  const [showModalInstallMetamask, setShowModalInstallMetamask] =
    useState(false);

  const { t } = UseTranslation(GroupLoginI18n);
  const handleClickMetamask = () => {
    if (window.ethereum) {
      handleLoginWeb3();
    } else {
      setShowModalInstallMetamask(true);
    }
  };
  return (
    <div>
      <ButtonType1
        icon={iconMetaMask}
        text={t("metaMask")}
        variation="thirty"
        isLoading={isLoading}
        props={{
          className: "btn-socials w-100",
          type: "button",
          style: { borderRadius: 8, padding: "13px 20px" },
          onClick: () => {
            handleClickMetamask();
          },
        }}
      />
      <ModalInstallMetamask
        onclick={() => setShowModalInstallMetamask(false)}
        modalInstallMetamask={showModalInstallMetamask}
        iconMetaMask={iconMetaMask}
      />
    </div>
  );
};

export default Web3Login;
