export const ModalInstallPhantomI18n  = {
    "pt-BR": {
      description: {
        label: "Parece que você não tem a carteira Phantom instalada no seu dispositivo. Clique no botão abaixo para instalar a Phantom!",
      },
      button: {
        download: "Baixar",
        close: "Voltar",
      },
    },
    "en-US": {
      description: {
        label: "It seems you don't have the Phantom wallet installed on your device. Click the button below to install Phantom!",
      },
      button: {
        download: "Download",
        close: "Close",
      },
    },
    "es-ES": {
      description: {
        label: "Parece que no tienes la cartera Phantom instalada en tu dispositivo. ¡Haz clic en el botón de abajo para instalar Phantom!",
      },
      button: {
        download: "Descargar",
        close: "Cerrar",
      },
    },
    "fil-PH": {
      description: {
        label: "Mukhang wala kang Phantom wallet na naka-install sa iyong device. I-click ang button sa ibaba para i-install ang Phantom!",
      },
      button: {
        download: "I-download",
        close: "Isara",
      },
    },
  };
  