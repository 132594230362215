import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();
const changePassword = (data: object) => {
  return api.update(url.USER_CHANGE_PASSWORD, data);
};

// postSocialLogin
const postSocialLogin = (data: any) => api.create(url.SOCIAL_LOGIN, data);

// ------------------------------
// WEB3 - Ethereum
// ------------------------------
const requestChallenge = async (address: string) => {
  const requestUrl =
    process.env.REACT_APP_API_URL +
    `/api/auth/web3/ethereum/requestChallenge/${address}`;

  return await api.create(encodeURI(requestUrl));
};

const verifyChallenge = async (data: any) => {
  const verifyUrl =
    process.env.REACT_APP_API_URL + "/api/auth/web3/ethereum/verifyChallenge";

  return await api.create(verifyUrl, data);
};

// ------------------------------
// WEB3 - Solana (Phantom)
// ------------------------------
const requestChallengePhantom = async (address: string) => {
  const requestUrl =
    process.env.REACT_APP_API_URL +
    `/api/auth/web3/solana/requestChallenge/${address}`;

  return await api.create(encodeURI(requestUrl));
};

const verifyChallengePhantom = async (data: any) => {
  const verifyUrl =
    process.env.REACT_APP_API_URL + "/api/auth/web3/solana/verifyChallenge";

  return await api.create(verifyUrl, data);
};

export {
  changePassword,
  postSocialLogin,

  // WEB3 - Ethereum
  requestChallenge,
  verifyChallenge,

  // WEB3 - Solana (Phantom)
  requestChallengePhantom,
  verifyChallengePhantom,
};
