import COLORS from "../../../utils/constants/colors";
import CardLandingPageType2 from "../../Cards/CardLandingPageType2";
import CardLandingPageType3 from "../../Cards/CardLandingPageType3";
import TitleLandingPage from "../LandingPage/TitleSectionLandingPage";
import imgIcon from "../../../assets/images/icons-new-layout/avatar-profile.svg";
import imgnoticeBitcoin from "../../../assets/images/icons-new-layout/notice-bitcoin.png";
import imgCardAssistant1 from "../../../assets/images/icons-new-layout/card-assistant-gradient-type2.svg";
import imgCardAssistant2 from "../../../assets/images/icons-new-layout/card-assistant-gradient-type1.svg";
import imgCardAssistant3 from "../../../assets/images/icons-new-layout/card-assistant-gradient-type3.svg";
import astronaut1 from "../../../assets/images/icons-new-layout/astronaut1.png";
import astronaut2 from "../../../assets/images/icons-new-layout/astronaut2.png";
import BREAKPOINTS from "../../../utils/constants/breakpoints";
import { useSelector } from "react-redux";
import CardAssistantLandingPage from "../../Cards/CardAssistantLandingPage";
import { LandingPageI18n } from "./LandingPageTranslactions";
import UseTranslation from "../../../hooks/useTranslation";
import iconAizzy from "../../../assets/images/icons-new-layout/icon.svg";
import ButtonFooterMessage from "../../Buttons/ButtonFooterMessage";
import { IconCopy, IconThumbDown, IconX } from "@tabler/icons-react";
import { useLayoutEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const SectionThreeLandingPage = () => {
  const state = useSelector(state => state);

  const { t } = UseTranslation(LandingPageI18n);

  const lg = state?.configsGeneralReducer?.windowWidth < BREAKPOINTS.LG;
  const sm = state?.configsGeneralReducer?.windowWidth > BREAKPOINTS.SM;

  const isScreenSm = state?.configsGeneralReducer?.windowWidth < BREAKPOINTS.SM;

  const translateX = sm && lg ? "translateX(-50%)" : "";

  const assistants = [
    {
      id: 1,
      title: "Blockchain Visionary",
      description:
        "Provide seamless and efficient assistance for your customers",
      name: "Aizzy Team",
      likes: 12,
      logo: iconAizzy,
      img: imgCardAssistant1,
      vertical: 156,
      horizontal: sm && lg ? "30%" : isScreenSm ? 35 : 60,
      rotate: lg ? "rotate(-13.56deg)" : "",
    },
    {
      id: 2,
      title: "Node JS. Developer",
      description:
        "Provide seamless and efficient assistance for your customers",
      name: "Aizzy Team",
      likes: 12,
      logo: iconAizzy,
      img: imgCardAssistant2,
      vertical: lg ? 171 : 194,
      horizontal: sm && lg ? "40%" : isScreenSm ? 111 : 260,
      rotate: "rotate(6.11deg)",
    },
    {
      id: 3,
      title: "Super Chat",
      description:
        "Provide seamless and efficient assistance for your customers",
      name: "Aizzy Team",
      likes: 12,
      logo: iconAizzy,
      img: imgCardAssistant3,
      vertical: lg ? 135 : 110,
      horizontal: sm && lg ? "50%" : isScreenSm ? 185 : 426,
      rotate: lg ? "rotate(10.88deg)" : "rotate(-5.88deg)",
    },
  ];

  useLayoutEffect(() => {
    const scroller = document.querySelector(".main-landing-page") || window;

    gsap.registerPlugin(ScrollTrigger);

    requestAnimationFrame(() => {
      ScrollTrigger.defaults({
        scroller,
      });

      gsap.to(".section-three", {
        opacity: 1,
        y: 0,
        duration: 1,
        scrollTrigger: {
          trigger: ".section-three",
          start: "top 100%",
          end: lg ? "bottom 290%" : "bottom 165%",
          scrub: true,
          scroller: ".main-landing-page",
        },
      });

      gsap.fromTo(
        ".card1-section-three",
        {
          opacity: 0,
          x: -500,
        },
        {
          opacity: 1,
          x: 0,
          duration: 1,
          scrollTrigger: {
            trigger: ".section-three",
            start: "top 100%",
            end: lg ? "bottom 265%" : "bottom 190%",
            scrub: true,
            scroller: ".main-landing-page",
          },
        }
      );

      gsap.fromTo(
        ".card2-section-three",
        {
          opacity: 0,
          x: 500,
        },
        {
          opacity: 1,
          x: 0,
          duration: 1,
          scrollTrigger: {
            trigger: ".section-three",
            start: "top 100%",
            end: lg ? "bottom 265%" : "bottom 190%",
            scrub: true,
            scroller: ".main-landing-page",
          },
        }
      );
    });

    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);

  return (
    <section
      className="vw-100 d-flex justify-content-center section-three"
      style={{ opacity: 0 }}
      id="capabilities"
    >
      <div
        className="w-100 d-flex flex-column align-items-center gap-4"
        style={{ maxWidth: 1440, padding: lg ? "120px 16px" : "80px 80px" }}
      >
        <TitleLandingPage
          darkMode
          mediumFont
          gap={24}
          alignItems={"center"}
          textAlign={"center"}
          headerText={t("sectionThree.badge")}
          headerWidth={100}
          widthTitle={500}
          border={`1px solid ${COLORS.gray200}`}
          borderRadius={100}
          title={t("sectionThree.title")}
          description={t("sectionThree.description")}
          showButton={false}
        />
        <div
          className={`d-flex ${
            lg && "flex-column align-items-center"
          } justify-content-between w-100 gap-3`}
          style={{ marginTop: 40 }}
        >
          <CardLandingPageType2
            background={COLORS.gray100}
            maxWidth={lg ? "100%" : 628}
            minHeight={lg ? 300 : 400}
            className={
              "d-flex justify-content-center justify-content-lg-start align-items-end position-relative card1-section-three"
            }
          >
            <CardLandingPageType3
              rotate={"rotate(-2.58deg)"}
              borderRadiusText={8}
              style={{
                position: "absolute",
                top: lg ? 18 : 28,
                left: lg && sm ? "50%" : isScreenSm ? 35 : 195,
                transform: translateX,
              }}
              maxWidth={lg ? 290 : 390}
              backgroundFather={COLORS.white}
              backgroundChildren={COLORS.gray100}
              text={<p className="one-line">{t("sectionThree.card1.chat1")}</p>}
              borderRadius={lg ? "5px 17px 17px 17px" : "8px 24px 24px 24px"}
              fontSize={lg ? 10 : 14}
            />
            <CardLandingPageType3
              rotate={"rotate(1.18deg)"}
              borderRadiusText={8}
              style={{
                position: "absolute",
                top: lg ? 71 : 110,
                left: lg && sm ? "50%" : isScreenSm ? 18 : 115,
                transform: translateX,
              }}
              imgIcon={imgCardAssistant3}
              maxWidth={lg ? 310 : 450}
              backgroundFather={COLORS.white}
              backgroundChildren={COLORS.gray100}
              text={
                <>
                  <p className="three-line position-relative">
                    {t("sectionThree.card1.chat2")}
                  </p>
                  <ButtonFooterMessage
                    icon={<IconCopy stroke={1.5} size={14} />}
                    style={{
                      position: "absolute",
                      bottom: -20,
                      right: lg ? "54%" : "46%",
                      transform: "translateX(50%)",
                    }}
                  />
                  <ButtonFooterMessage
                    icon={<IconX stroke={1.5} size={14} />}
                    style={{
                      position: "absolute",
                      bottom: -20,
                      right: lg ? "41%" : "37%",
                      transform: "translateX(50%)",
                    }}
                  />
                  <ButtonFooterMessage
                    icon={<IconThumbDown stroke={1.5} size={14} />}
                    style={{
                      position: "absolute",
                      bottom: -20,
                      right: "28%",
                      transform: "translateX(50%)",
                    }}
                  />
                </>
              }
              borderRadius={lg ? "5px 17px 17px 17px" : "8px 24px 24px 24px"}
              fontSize={lg ? 10 : 14}
            />
            <TitleLandingPage
              darkMode
              gap={lg ? 8 : 12}
              justifyContent={"start"}
              alignItems={"flex-start"}
              title={t("sectionThree.card1.title")}
              description={
                <p className="two-line">
                  {t("sectionThree.card1.description")}
                </p>
              }
            />
          </CardLandingPageType2>
          <CardLandingPageType2
            background={COLORS.gray100}
            maxWidth={lg ? "100%" : 628}
            minHeight={lg ? 300 : 400}
            className={
              "position-relative d-flex justify-content-lg-start justify-content-center align-items-start card2-section-three"
            }
          >
            <TitleLandingPage
              darkMode
              style={{ position: "relative", zIndex: 5 }}
              gap={lg ? 8 : 12}
              justifyContent={"start"}
              textAlign={"start"}
              alignItems={"flex-start"}
              title={t("sectionThree.card2.title")}
              description={
                <p className="two-line">
                  {t("sectionThree.card2.description")}
                </p>
              }
            />
            <img
              src={astronaut1}
              alt=""
              width={"100%"}
              style={{
                position: "absolute",
                bottom: lg ? 60 : 127,
                left: sm && lg ? "60%" : isScreenSm ? 188 : 388,
                transform: translateX,
                maxWidth: lg ? 125 : 184,
              }}
            />
            <img
              src={astronaut2}
              alt=""
              width={"100%"}
              style={{
                position: "absolute",
                bottom: lg ? 27 : 13,
                left: sm && lg ? "50%" : isScreenSm ? 156 : 352,
                transform: translateX,
                maxWidth: lg ? 125 : 184,
              }}
            />
            <img
              src={astronaut2}
              alt=""
              width={"100%"}
              style={{
                position: "absolute",
                bottom: lg ? 41 : 54,
                left: sm && lg ? "30%" : isScreenSm ? 33 : 189,
                maxWidth: lg ? 125 : 184,
                transform: "rotate(-7.5deg)",
              }}
            />
            <CardLandingPageType3
              borderRadiusText={8}
              style={{
                position: "absolute",
                bottom: lg ? 22 : 50,
                left: sm && lg ? "25%" : isScreenSm ? 23 : 52,
              }}
              maxWidth={lg ? 173 : 279}
              backgroundFather={COLORS.white}
              backgroundChildren={COLORS.gray100}
              text={<p className="one-line">{t("sectionThree.card2.chat")}</p>}
              borderRadius={lg ? "5px 17px 17px 17px" : "8px 24px 24px 24px"}
              fontSize={lg ? 10 : 14}
            />
          </CardLandingPageType2>
        </div>
        <CardLandingPageType2
          background={COLORS.gray900}
          minHeight={lg ? 360 : 400}
          maxWidth={"100%"}
          className={
            "position-relative d-flex justify-content-lg-start justify-content-center align-items-start"
          }
        >
          <TitleLandingPage
            gap={lg ? 8 : 12}
            justifyContent={"start"}
            textAlign={"start"}
            alignItems={"flex-start"}
            showButton={lg ? false : true}
            buttonPadding={"8px 16px"}
            buttonStyle={{
              background: COLORS.white,
              color: COLORS.gray900,
              marginTop: 20,
              alignSelf: "flex-start",
            }}

            title={
              <h4 style={{ fontWeight: "600", color: COLORS.white, textAlign: "start", width: "100%" }}>
                {t("sectionThree.card3.title")}
              </h4>
            }
            description={
              <p className="three-line" style={{ color: COLORS.gray400 }}>
                {t("sectionThree.card3.description")}
              </p>
            }
          />
          <CardLandingPageType3
            borderRadiusText={8}
            style={{
              position: "absolute",
              top: lg ? 152 : 80,
              left: lg && sm ? "35%" : isScreenSm ? 23 : 533,
              zIndex: 90,
            }}
            maxWidth={lg ? 250 : 477}
            backgroundFather={"#F2F4F71F"}
            backgroundChildren={"#FFFFFF29"}
            text={
              <p className="one-line" style={{ color: COLORS.white }}>
                {t("sectionThree.card3.chat1")}
              </p>
            }
            borderRadius={lg ? "17px 5px 17px 17px" : "24px 8px 24px 24px "}
            rotate={"rotate(1.58deg)"}
            fontSize={lg ? 8 : 14}
          />
          <CardLandingPageType3
            borderRadiusText={8}
            style={{
              position: "absolute",
              top: lg ? 197 : 159,
              left: lg && sm ? "40%" : isScreenSm ? 75 : 667,
              zIndex: 95,
            }}
            maxWidth={lg ? 250 : 477}
            backgroundFather={"#F2F4F71F"}
            backgroundChildren={"#FFFFFF29"}
            text={
              <p style={{ color: COLORS.white }}>
                {t("sectionThree.card3.chat2.header")} <br /> <br />
                1. {t("sectionThree.card3.chat2.one")} <br />
                {""} - {t("sectionThree.card3.chat2.dotOne")} <br />
                <br />
                <br />
                2. {t("sectionThree.card3.chat2.two")} -{" "}
                {t("sectionThree.card3.chat2.dotTwo")}
              </p>
            }
            borderRadius={"8px 24px 24px 24px "}
            rotate={"rotate(-2.58deg)"}
            imgIcon={imgCardAssistant3}
            fontSize={lg ? 8 : 14}
          />
          {lg ? (
            ""
          ) : (
            <>
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: 890,
                  height: 890,
                  borderRadius: "50%",
                  background: COLORS.white,
                  zIndex: 1,
                  filter: "blur(200px)",
                  opacity: "11%",
                }}
              ></div>
              <div
                style={{
                  border: `1px solid ${COLORS.gray800}`,
                  position: "absolute",
                  top: 207,
                  left: -550,
                  width: 800,
                  height: 800,
                  borderRadius: "50%",
                  zIndex: 2,
                }}
              ></div>
              <div
                style={{
                  border: `1px solid ${COLORS.gray800}`,
                  position: "absolute",
                  bottom: 232,
                  right: -550,
                  width: 800,
                  height: 800,
                  borderRadius: "50%",
                  zIndex: 3,
                }}
              ></div>
            </>
          )}
        </CardLandingPageType2>

        <div
          className={`d-flex ${
            lg && "flex-column align-items-center"
          } justify-content-between w-100 gap-3`}
        >
          <CardLandingPageType2
            background={COLORS.gray100}
            maxWidth={lg ? "100%" : 628}
            minHeight={lg ? 300 : 400}
            className={
              "d-flex justify-content-lg-start justify-content-center align-items-end position-relative"
            }
          >
            <CardLandingPageType3
              rotate={lg ? "rotate(-8.18deg)" : "rotate(1.18deg)"}
              borderRadiusText={8}
              style={{
                position: "absolute",
                top: 28,
                left: lg && sm ? "30%" : 28,
              }}
              imgIcon={imgIcon}
              maxWidth={lg ? 230 : 359}
              backgroundFather={COLORS.white}
              backgroundChildren={COLORS.gray100}
              text={
                <>
                  <p className="position-relative">
                    {t("sectionThree.card4.chat")}
                  </p>
                </>
              }
              borderRadius={lg ? "15px 5px 15px 15px" : "8px 24px 24px 24px "}
              fontSize={lg ? 9 : 14}
            />
            {lg ? (
              ""
            ) : (
              <CardLandingPageType3
                rotate={"rotate(13deg)"}
                borderRadiusText={8}
                style={{
                  position: "absolute",
                  top: 102,
                  left: 423,
                  height: 194,
                }}
                maxWidth={164}
                backgroundFather={COLORS.white}
                backgroundChildren={COLORS.white}
                borderRadius={"8px 24px 24px 24px "}
              />
            )}

            <TitleLandingPage
              gap={lg ? 8 : 12}
              justifyContent={"start"}
              textAlign={"start"}
              alignItems={"flex-start"}
              title={
                <h4 style={{ fontWeight: "600" }}>
                  {t("sectionThree.card4.title")}
                </h4>
              }
              description={
                <p className="two-line">
                  {t("sectionThree.card4.description")}
                </p>
              }
            />
            <img
              src={imgnoticeBitcoin}
              alt=""
              style={{
                position: "absolute",
                top: lg ? 35 : 68,
                left: sm && lg ? "57%" : isScreenSm ? 217 : 410,
                width: lg ? 77 : 154,
                height: lg ? 110 : 201,
                transform: "rotate(13deg)",
                borderRadius: lg ? 6 : 12,
              }}
            />
          </CardLandingPageType2>
          <CardLandingPageType2
            background={COLORS.gray100}
            maxWidth={lg ? "100%" : 628}
            minHeight={lg ? 300 : 400}
            className={
              "position-relative overflow-hidden d-flex justify-content-lg-start justify-content-center align-items-start"
            }
          >
            <TitleLandingPage
              gap={lg ? 8 : 12}
              justifyContent={"start"}
              textAlign={"start"}
              alignItems={"flex-start"}
              title={
                <h4 style={{ fontWeight: "600" }}>
                  {t("sectionThree.card5.title")}
                </h4>
              }
              description={
                <p className="two-line">
                  {t("sectionThree.card5.description")}
                </p>
              }
            />
            {assistants.map((assistant, index) => (
              <div
                style={{
                  width: lg ? 136 : 220,
                  position: "absolute",
                  top: assistant.vertical,
                  left: assistant.horizontal,
                  transform: assistant.rotate,
                }}
              >
                <CardAssistantLandingPage
                  key={index}
                  image={assistant.img}
                  title={assistant.title}
                  description={assistant.description}
                  icon={assistant.logo}
                  name={assistant.name}
                  assistant={assistant}
                />
              </div>
            ))}
          </CardLandingPageType2>
        </div>
      </div>
    </section>
  );
};

export default SectionThreeLandingPage;
