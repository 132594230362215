export const PageAssistantsI18n = {
  "pt-BR": {
    sigIn: "Entrar",
    button: "Ver mais",
    create: "Criar",
    publish: "Publicar Agente",
    title: "Potencialize Sua Jornada",
    paragraph: "Desbloqueie o potencial do seu fluxo de trabalho com nossos agentes intuitivos",
    tabs: {
      top: "Melhores escolhas",
      aizzy: "App Aizzy",
      community: {
        title: "Comunidade",
        previous: "Página anterior",
        next: "Próxima página",
        totalAssistants: "Total de agentes",
      },
      my: {
        title: "Meus Agentes",
        noAssistants: "Você ainda não tem nenhum agente criado...",
        create: "Crie agora seu próprio agente!",
      },
    },
    subTitles: {
      trending: "Em alta",
      byAizzyApp: "Por Aizzy App",
      paragraphTrending: "Principais agentes usados",
      paragraphAizzyApp: "Explore os agentes criados pela equipe AizzyApp",
    },
  },
  "en-US": {
    sigIn: "Sign In",
    button: "See More",
    create : "Create",
    publish: "Publish Agent",
    title: "Empower Your Journey",
    paragraph: "Unleash the potential of your workflow with our intuitive agents",
    tabs: {
      top: "Top picks",
      aizzy: "Aizzy App",
      community: {
        title: "Community",
        previous: "Previous Page",
        next: "Next Page",
        totalAssistants: "Total Agents",
      },
      my: {
        title: "My Agents",
        noAssistants: "You don't have any agent created yet...",
        create: "Create now your personal agent!",
      }
    },
    subTitles: {
      trending: "Trending",
      byAizzyApp: "By Aizzy App",
      paragraphTrending: "Top used agents",
      paragraphAizzyApp: "Explore the agents created by AizzyApp team",
    },
  },
  "es-ES": {
    sigIn: "Iniciar sesión",
    button: "Ver más",
    create : "Crear",
    publish: "Publicar Agente",
    title: "Potencia Tu Viaje",
    paragraph: "Desbloquea el potencial de tu flujo de trabajo con nuestros agentes intuitivos",
    tabs: {
      top: "Mejores elecciones",
      aizzy: "App Aizzy",
      community: {
        title: "Comunidad",
        previous: "Página anterior",
        next: "Próxima página",
        totalAssistants: "Total de agentes",
      },
      my: {
        title: "Mis Agentes",
        noAssistants: "No tienes ninguño agente creado todavía...",
        create: "Cree ahora su agente personal!",
      },
    },
    subTitles: {
      trending: "Tendencias",
      byAizzyApp: "Por Aizzy App",
      paragraphTrending: "Principales agentes utilizados",
      paragraphAizzyApp: "Explora los agentes creados por el equipo de AizzyApp",
    },
  },
};