export const PageMaintenanceI18n = {
    "pt-BR": {
        title: "Site em manutenção",
        description: "A AizzyAI está passando por uma manutenção programada. Voltaremos em breve.",
        buttons: {
            launch: "Abrir Aplicativo",
            home: "Voltar para Início",
        }
    },
    "en-US": {
        title: "Website under maintenance",
        description: "AizzyAI is currently undergoing scheduled maintenance. We should be back shortly.",
        buttons: {
            launch: "Launch App",
            home: "Back to Home",
        }
    },
    "es-ES": {
        title: "Sitio web en mantenimiento",
        description: "AizzyAI está realizando un mantenimiento programado. Volveremos pronto.",
        buttons: {
            launch: "Abrir Aplicación",
            home: "Volver al Inicio",
        }
    },
};
