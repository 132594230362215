import { useSelector } from "react-redux";
import logoAizzy from "../../../assets/images/icons-new-layout/logoFooter.svg";
import BREAKPOINTS from "../../../utils/constants/breakpoints";
import { Link, useLocation } from "react-router-dom";
import SocialMideas from "../../../utils/constants/SocialMedia";
import COLORS from "../../../utils/constants/colors";
import { LandingPageI18n } from "./LandingPageTranslactions";
import UseTranslation from "../../../hooks/useTranslation";

const FooterLandingPage = () => {
  const state = useSelector(state => state);

  const { t } = UseTranslation(LandingPageI18n);

  const location = useLocation();

  const breakpointLg =
    state?.configsGeneralReducer?.windowWidth < BREAKPOINTS.LG;

  return (
    <section
      className={"d-flex flex-column justify-content-center align-items-center"}
      style={{
        padding: breakpointLg ? "0 16px" : !location.pathname === "/roadmap" && "80px 80px 0 80px",
        gap: 80,
        width: "100%",
      }}
    >
      <div
        className="w-100 d-flex flex-column justify-content-center align-items-center"
        style={{
          gap: 80,
          maxWidth: 1440,
          padding: breakpointLg ? "80px 0px" : "80px 80px 0 80px",
        }}
      >
        <div
          className="d-flex justify-content-between"
          style={{
            width: "100%",
            flexDirection: breakpointLg ? "column" : "row",
            gap: breakpointLg ? 40 : 0,
          }}
        >
          <div>
            <h5 className="font-size-14">{t("footer.description")}</h5>
          </div>
          <div
            className="d-grid footer-landing-page"
            style={{
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: breakpointLg ? 50 : 80,
              fontSize: breakpointLg ? 12 : 14,
            }}
          >
            <ul className="d-flex flex-column gap-lg-4 gap-3">
              <li>
                <span>{t("footer.explore.title")}</span>
              </li>
              <li>
                <Link target="_blank" to={"https://github.com/aizzy-ai"}>
                  {t("footer.explore.github")}
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  to={"https://github.com/aizzy-ai/aizzy-token"}
                >
                  {t("footer.explore.aiz")}
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  to={
                    "https://basescan.org/token/0xc51d94a9936616b90E26abe61921Ab3b4E66A149"
                  }
                >
                  {t("footer.explore.base")}
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  to={
                    "https://app.uniswap.org/explore/tokens/base/0xc51d94a9936616b90e26abe61921ab3b4e66a149"
                  }
                >
                  {t("footer.explore.uniswap")}
                </Link>
              </li>
            </ul>
            <ul className="d-flex flex-column gap-4">
              <li>
                <span>{t("footer.learnMore.title")}</span>
              </li>
              <li>
                <Link target="_blank" to={"https://github.com/aizzy-ai"}>
                  {t("footer.learnMore.about")}
                </Link>
              </li>
              {/* <li>
              <Link target="_blank" to={""}>{t("footer.learnMore.faqs")}</Link>
            </li> */}
            </ul>
          </div>
        </div>
        <div
          className="d-flex justify-content-between"
          style={{ width: "100%" }}
        >
          <span
            className="font-size-12"
            style={{ color: COLORS.gray400, marginRight: 16 }}
          >
            © 2024 Aizzy. All rights reserved.
          </span>
          <ul className="d-flex gap-2">
            {SocialMideas.map((social, index) => (
              <li key={index}>
                <Link
                  to={social.link}
                  target="_blank"
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    width: 24,
                    height: 24,
                    borderRadius: 6,
                    background: COLORS.gray100,
                  }}
                >
                  <img src={social.image} alt={social.name} />
                </Link>
              </li>
            ))}
          </ul>
        </div>
        {breakpointLg ? (
          ""
        ) : (
          <div className="w-100 position-relative">
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                width: "100%",
              }}
            >
              <img src={logoAizzy} alt="" style={{ width: "100%" }} />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default FooterLandingPage;
