export const PageChatI18n = {
  "pt-BR": {
    tooltip: {
      credits: {
        your: "Seus créditos",
        buy: "Compre créditos",
      },
      chatting: "Converse com a IA em uma conversa normal",
      coding:
        "Ao ativado, traz os modelos mais apropriados para o código no popup de modelos.",
      webSearching:
        "Ao ativado, essa característica permite que a IA faça buscas na internet para fornecer respostas precisas e atualizadas para sua pergunta.",
    },
    assistants: "Agentes",
    models: "Modelos",
    account: "Conta",
    credits: "Créditos",
    settings: "Configurações",
    wallet: "Carteira",
    anonymously: "Chat Anônimo",
  },
  "en-US": {
    tooltip: {
      credits: {
        your: "Your credits",
        buy: "Buy credits",
      },
      chatting: "Chat with the AI in a normal conversation",
      coding:
        "When activated, it brings up the most suitable models for code in the models popup.",
      webSearching:
        "When activated, this feature allows the IA to conduct Internet searches to provide precise and up-to-date answers to your query.",
    },
    assistants: "Agents",
    models: "Models",
    account: "Account",
    credits: "Credits",
    settings: "Settings",
    wallet: "Wallet",
    anonymously: "Chat Anonymously",
  },
  "es-ES": {
    tooltip: {
      credits: {
        your: "Sus créditos",
        buy: "Compre créditos",
      },
      chatting: "Chatea con la IA en una conversación normal",
      coding:
        "Cuando está activado, trae los modelos más adecuados para el código en el popup de modelos.",
      webSearching:
        "Cuando está activado, esta característica permite que la IA realice búsquedas en Internet para proporcionar respuestas precisas y actualizadas a su pregunta.",
    },
    assistants: "Agentes",
    models: "Modelos",
    account: "Cuenta",
    credits: "Créditos",
    settings: "Configuraciones",
    wallet: "Cartera",
    anonymously: "Chat Anónimo",
  },
};
