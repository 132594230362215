export const SideMenuType2I18n = {
  "pt-BR": {
    header: {
      button: "Novo chat",
      search: "Pesquisar",
      searchResults: "Resultados da busca",
    },
    groupList: {
      today: "Hoje",
      yesterday: "Ontem",
      lastDays: "Últimos 7 dias",
      last30Days: "Últimos 30 dias",
    },
    footer: {
      assistants: "Agentes",
      settings: "Configurações",
      credits: "Créditos",
      help: "Sobre o aplicativo",
      follow: "Contate-nos",
      deleteAllChats: "Excluir todos os chats",
    },
  },
  "en-US": {
    header: {
      button: "New chat",
      search: "Search",
      searchResults: "Search results",
    },
    groupList: {
      today: "Today",
      yesterday: "Yesterday",
      lastDays: "Last 7 days",
      last30Days: "Last 30 days",
    },
    footer: {
      assistants: "Agents",
      settings: "Settings",
      credits: "Credits",
      help: "About the App",
      follow: "Contact us",
      deleteAllChats: "Delete all chats",
    },
  },
  "es-ES": {
    header: {
      button: "Nuevo chat",
      search: "Buscar",
      searchResults: "Resultados de la búsqueda",
    },
    groupList: {
      today: "Hoy",
      yesterday: "Ayer",
      lastDays: "Últimos 7 días",
      last30Days: "Últimos 30 días",
    },
    footer: {
      assistants: "Agentes",
      settings: "Configuraciones",
      credits: "Créditos",
      help: "Sobre el aplicativo",
      follow: "Contactanos",
      deleteAllChats: "Eliminar todos los chats",
    },
  },
};
