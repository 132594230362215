import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import CardRoadmap from "./CardRoadmap";
import CardRoadmapType1 from "./CardRoadmapType1";
import "swiper/swiper.min.css";
import CardStatusRoadmap from "./CardStatusRoadmap";
import COLORS from "../../utils/constants/colors";
import iconAizzyRoadmap from "../../assets/images/icons-new-layout/aizzy-icon-roadmap.svg";
import iconRobotRoadmap from "../../assets/images/icons-new-layout/robot-roadmap.svg";
import iconAizzyMobileRoadmap from "../../assets/images/icons-new-layout/mobile-roadmap.svg";
import { RoadmapTranslationsI18n } from "../PagesComponents/Roadmap/RoadmapTranslations.i18n";
import UseTranslation from "../../hooks/useTranslation";

const CardsSwiperRoadmap = () => {
  const { t } = UseTranslation(RoadmapTranslationsI18n);
  return (
    <Swiper
      modules={[Navigation, Pagination]}
      spaceBetween={24}
      slidesPerView={"auto"}
      style={{ width: "100%", padding: "0 16px", gap: 24 }}
      className="swiper-container-roadmap"
    >
      <SwiperSlide style={{ width: "auto" }}>
        <CardRoadmap
          cardCount={1}
          semesters={[3, 4]}
          year={2024}
          card={
            <CardRoadmapType1
              maxWidth={380}
              text={
                <>
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 >{t("cardOne.title")}</h5>
                    <CardStatusRoadmap
                      background={COLORS.green50}
                      status={t("cardOne.status")}
                      color={COLORS.green800}
                    />
                  </div>
                  <p className="m-0 font-size-12">
                    {t("cardOne.description")}
                  </p>
                </>
              }
              img={iconAizzyRoadmap}
              top={100}
              left={15}
            />
          }
          imagesCount={2}
        />
      </SwiperSlide>
      <SwiperSlide style={{ width: "auto", zIndex: 100 }}>
        <CardRoadmap
          cardCount={1}
          semesters={[1, 2]}
          year={2025}
          card={
            <>
              <CardRoadmapType1
              style={{ minWidth: 600 }}
                text={
                  <>
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 >{t("cardTwo.title")}</h5>
                      <CardStatusRoadmap
                        background={COLORS.gray100}
                        status={t("cardTwo.status")}
                        color={COLORS.gray500}
                      />
                    </div>
                    <p className="m-0 font-size-12">
                      {t("cardTwo.description")}
                    </p>
                  </>
                }
                img={iconRobotRoadmap}
                top={200}
                left={10}
              />
              <CardRoadmapType1
                maxWidth={380}
                text={
                  <>
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>{t("cardThree.title")}</h5>
                      <CardStatusRoadmap
                        background={COLORS.gray100}
                        status={t("cardThree.status")}
                        color={COLORS.gray500}
                      />
                    </div>
                    <p className="m-0 font-size-12">
                      {t("cardThree.description")}
                    </p>
                  </>
                }
                img={iconAizzyMobileRoadmap}
                top={320}
                left={230}
              />
            </>
          }
          imagesCount={4}
        />
      </SwiperSlide>
      <SwiperSlide style={{ width: "auto", zIndex: 1 }}>
        <CardRoadmap semesters={[3]} year={2025} imagesCount={1} />
      </SwiperSlide>
      <SwiperSlide style={{ width: "auto" }}>
        <CardRoadmap semesters={[4]} year={2025} imagesCount={1} />
      </SwiperSlide>
    </Swiper>
  );
};

export default CardsSwiperRoadmap;
