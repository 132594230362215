const CardStatusRoadmap = ({ background, status, color }) => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        background: background,
        borderRadius: 4,
        padding: "2px 4px",
        height: 15,
      }}
    >
      <span
        className="d-flex align-items-center"
        style={{ fontSize: 9, display: "flex", alignItems: "center", color: color }}
      >
        <span
          style={{
            width: 4,
            height: 4,
            borderRadius: "50%",
            backgroundColor: color,
            marginRight: 4,
          }}
        ></span>
        {status}
      </span>
    </div>
  );
};

export default CardStatusRoadmap;
