import {
  IconArrowUpRight,
  IconBolt,
  IconChevronDown,
  IconLoader2,
} from "@tabler/icons-react";
import COLORS from "../../utils/constants/colors";
import { UserSubscription } from "./UserSubscription";
import UseUserCredits from "../../hooks/useUserCredits";
import { useUser } from "@supabase/auth-helpers-react";
import { Link, useNavigate } from "react-router-dom";
import SocialMideas from "../../utils/constants/SocialMedia";
import { useGetIsLogged } from "../../hooks/smallHooks";
import { useDispatch } from "react-redux";
import { setOpenLeftBar } from "../../redux/general/action";
import imgProfile from "../../assets/images/icons-new-layout/avatar-profile.svg";
import logoAizzy from "../../assets/images/icons-new-layout/smallLogoAizzy.svg";
import { useState } from "react";
import { truncateNumber } from "../../utils/stringutils";

const CardFooterSideLeftBar = ({ t }) => {
  const { credits, hasSubscriptionActive, isLoadingCredits } = UseUserCredits();
  const user = useUser();
  const isSubscriptionActive = hasSubscriptionActive();
  const navigate = useNavigate();
  const logged = useGetIsLogged();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);

  const avatarProfile = user?.user_metadata?.picture;

  return (
    <div className={"footer-aside-nav"}>
      <div
        className="d-flex flex-column gap-3"
        style={{
          color: COLORS.gray700,
          padding: 12,
          borderRadius: 12,
          backgroundColor: COLORS.gray100,
          gap: 12,
        }}
      >
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-2">
            <img
              src={avatarProfile ? avatarProfile : imgProfile}
              alt=""
              style={{ borderRadius: "50%", width: 28, height: 28 }}
            />
            <span className="one-line font-size-14">
              {user && user?.user_metadata?.name
                ? user?.user_metadata?.name
                : user?.email?.split("@")[0]}
            </span>
          </div>
          <IconChevronDown
            className="cursor-pointer"
            style={{
              transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease-in-out",
            }}
            stroke={1.5}
            size={16}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
        </div>
        {isOpen && (
          <div
            className="d-flex align-items-center gap-2 cursor-pointer animate__animated animate__fadeIn"
            style={{ background: COLORS.white, padding: 8, borderRadius: 8 }}
            onClick={() =>
              logged
                ? navigate("/settings?tab=credits")
                : dispatch(setOpenLeftBar(true))
            }
          >
            {isSubscriptionActive ? (
              <div className="d-flex w-100 justify-content-center">
                <UserSubscription />
              </div>
            ) : (
              <>
                {isLoadingCredits ? (
                  <div className="w-100 d-flex align-items-center justify-content-center">
                    <IconLoader2 className="spin" stroke={1.5} size={16} />
                  </div>
                ) : (
                  <div className="w-100 d-flex align-items-center justify-content-between gap-2 font-size-12">
                    <div className="d-flex align-items-center gap-2">
                      <IconBolt stroke={1.5} size={16} />
                      <span>{t("footer.credits")}</span>
                    </div>
                    <span className="d-flex align-items-center">
                      $
                      {(credits?.amount
                        ? truncateNumber(credits.amount || 0, 2)
                        : 0
                      ).toFixed(2)}{" "}
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
      <div className="d-flex flex-column gap-2">
        <div className="d-flex align-items-center gap-3">
          <span style={{ color: COLORS.gray400 }} className="font-size-12">
            {t("footer.help")}
          </span>
          <a href="/" className="d-flex align-items-center">
            <img
              src={logoAizzy}
              alt=""
              width={50}
              height={16}
              style={{ cursor: "pointer" }}
            />
          </a>
        </div>
        <div className="d-flex align-items-center justify-content-between gap-2">
          <div
            className="d-flex align-items-center gap-1"
            style={{ color: COLORS.gray400 }}
          >
            <u className="font-size-12">{t("footer.follow")}</u>
            <IconArrowUpRight stroke={1.5} width={14} height={14} />
          </div>
          <ul className="d-flex align-items-center gap-2">
            {SocialMideas.map((social, index) => (
              <li key={index} className="d-flex align-items-center">
                <Link
                  to={social.link}
                  target="_blank"
                  className="d-flex align-items-center"
                >
                  <img src={social.image} alt={social.name} />
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CardFooterSideLeftBar;
