export const ModalSelectAssistantsI18n = {
  "pt-BR": {
    title:"Selecionar Agente",
    tabs: {
      aizzy:"Aizzy App",
      comminuty:"Comunidade",
      my:"Meus Agentes",
    },
  },
  "en-US": {
    title:"Select Agent",
    tabs: {
      aizzy:"Aizzy App",
      comminuty:"Community",
      my:"My Agents",
    },
  },
  "es-ES": {
    title:"Seleccionar Agente",
    tabs: {
      aizzy:"Aizzy App",
      comminuty:"Comunidad",
      my:"Mis Agentes",
    },
  },
};