// src/utils/loadModels.js

import { setListMachines } from "../redux/general/action";
import { toast } from "sonner";
import { getModels } from "../api";

// Modificando para aceitar dispatch e isLogged como parâmetros
export const loadModels = async (dispatch, isLogged) => {
  const cacheKey = "modelsCache";
  const cacheDuration = 24 * 60 * 60 * 1000;

  const cachedData = localStorage.getItem(cacheKey);
  const now = new Date().getTime();

  if (cachedData) {
    const parsedData = JSON.parse(cachedData);
    const cacheTime = parsedData.timestamp;

    if (now - cacheTime < cacheDuration) {
      dispatch(setListMachines(parsedData.models));
      return;
    }
  }

  if (isLogged) {
    try {
      const res = await getModels();

      if (!res || !res?.data) {
        toast.error("An error has occurred. Please try again.");
        return;
      }
      const modelsData = res.data;

      localStorage.setItem(
        cacheKey,
        JSON.stringify({ models: modelsData, timestamp: now })
      );

      dispatch(setListMachines(modelsData));
    } catch (error) {
      console.error("Erro ao buscar modelos:", error ? error : "Unknown error");
    }
  } 
};
