const COLORS = {
  white: "#FFFFFF",
  gray100: "#F2F4F7",
  gray200: "#E4E7EC",
  gray300: "#D0D5DD",
  gray400: "#98A2B3",
  gray500: "#667085",
  gray700: "#344054",
  gray800: "#1D2939",
  gray900: "#101828",
  green50: "#ECFDF3",
  green800: "#05603A",
};

export default COLORS;
