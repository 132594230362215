/* eslint-disable react-hooks/exhaustive-deps */
import CardAssistantsNewLayout from "../../Cards/CardAssistantsNewLayout";
import { setConfigsMainChat } from "../../../redux/general/action";
import { KEYS_MAIN_CHAT } from "../../../services/chat/defaultConfigsMainChat";
import { useDispatch } from "react-redux";
import { QUERY_KEYS } from "../../../libs/ReactQuery";
import { useEffect, useState } from "react";
import ModalAssistantInfo from "../../Modals/ModalInfoAssistants";
import { useQuery } from "react-query";
import { getCompanyAssistants } from "../../../api/assistants";
import { showRealoadNotification } from "../../../services/notifications";

const ListAssistantsAizzy = () => {
  let showError = false;
  const dispatch = useDispatch();
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [selectedAssistant, setSelectedAssistant] = useState(null);

  const { data, error } = useQuery(QUERY_KEYS.AIZZY_ASSISTANTS, getCompanyAssistants, {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: Infinity,
  });

  const handleCardClick = assistant => {
    setSelectedAssistant(assistant);
    setOpenModalInfo(true);
  };

  useEffect(() => {
    showRealoadNotification((error ? error?.message : null), showError);
    showError = true;
 }, [error]);

  return (
    <div className="grid-vertical-assistants">
      {data && data?.assistants?.map((assistant, index) => (
        <CardAssistantsNewLayout
          setOpenModalInfo={() => handleCardClick(assistant)}
          key={index}
          direction="vertical"
          image={assistant.profileImage}
          title={assistant.name}
          description={assistant.role}
          icon={assistant.profileImage}
          name={assistant.role}
          assistant={assistant}
          onSelect={() =>
            dispatch(setConfigsMainChat(KEYS_MAIN_CHAT.assistant, assistant))
          }
        />
      ))}
      <ModalAssistantInfo
        isOpen={openModalInfo}
        toggleModal={() => {
          setOpenModalInfo(false);
        }}
        assistant={selectedAssistant}
      />
    </div>
  );
};

export default ListAssistantsAizzy;
