import FooterLandingPage from "../PagesComponents/LandingPage/FooterLandingPage";
import COLORS from "../../utils/constants/colors";
import iconAizzy from "../../assets/images/icons-new-layout/icon.svg";
import BREAKPOINTS from "../../utils/constants/breakpoints";
import { useSelector } from "react-redux";
import UseTranslation from "../../hooks/useTranslation";
import { PageMaintenanceI18n } from "./PageMaintenance.i18n";
import NavLandingPage from "../PagesComponents/LandingPage/NavLandingPage";

const PageMaintenance = () => {
  const { t } = UseTranslation(PageMaintenanceI18n);
  const state = useSelector(state => state);

  const isMobile = state.configsGeneralReducer.windowWidth < BREAKPOINTS.MD;
  return (
    <>
      <NavLandingPage />
      <div
        className="d-flex flex-column justify-content-between w-100 gap-5 overflow-auto"
        style={{ paddingTop: isMobile ? "180px" : "140px", height: "100vh" }}
      >
        <div
          className="d-flex justify-content-center align-items-center w-100 h-100"
          style={{ padding: !isMobile && "80px 80px 0 80px", flexGrow: 1, maxHeight: isMobile && "500px" }}
        >
          <div
            className="d-flex justify-content-center align-items-center w-100 h-100"
            style={{ maxWidth: "900px" }}
          >
            <div
              className="circle d-flex justify-content-center align-items-center position-relative"
              style={{
                width: isMobile ? "100%" : "650px",
                height: isMobile ? "320px" : "650px",
                borderRadius: "50%",
                background: `linear-gradient(180deg, ${COLORS.gray100} 10%, #fff 30%)`,
              }}
            >
              <img
                src={iconAizzy}
                alt=""
                width={isMobile ? 80 : 150}
                height={isMobile ? 80 : 150}
                style={{ marginTop: isMobile ? "-130px" : "-280px" }}
              />
              <div
                className="d-flex flex-column justify-content-center align-items-center position-absolute"
                style={{
                  width: "100%",
                  height: isMobile ? "70%" : "60%",
                  bottom: isMobile ? 0 : 80,
                  filter: "blur(30px)",
                  backdropFilter: "blur(30px)",
                  zIndex: 11,
                }}
              ></div>
              <div
                className="d-flex flex-column justify-content-start align-items-center position-absolute gap-3"
                style={{
                  width: "100%",
                  bottom: isMobile ? -30 : 180,
                  zIndex: 11,
                }}
              >
                <h1 className="text-center m-0">{t("title")}</h1>
                <span
                  className="text-center"
                  style={{
                    fontSize: isMobile ? "14px" : "16px",
                    maxWidth: 350,
                  }}
                >
                  {t("description")}
                </span>
              </div>
            </div>
          </div>
        </div>
        <FooterLandingPage />
      </div>
    </>
  );
};

export default PageMaintenance;
