import { useSelector } from "react-redux";
import BREAKPOINTS from "../../utils/constants/breakpoints";

const CardLandingPageType2 = ({
  background,
  maxWidth,
  children,
  minHeight,
  className,
  style
}) => {
  const state = useSelector(state => state);

  const lg = state.configsGeneralReducer.windowWidth < BREAKPOINTS.LG;
  return (
    <div
      className={className}
      style={{
        background: background,
        borderRadius: 24,
        width: "100%",
        height: "100%",
        maxWidth: maxWidth,
        minHeight: minHeight,
        padding: lg ? 24 : 32,
        overflow: "hidden",
        ...style
      }}
    >
      {children}
    </div>
  );
};

export default CardLandingPageType2;
