export const ModalImagensAvatarI18n = {
    "pt-BR": {
        title: "Escolha um avatar para seu agente",
        button: "Gerar",
      },
    "en-US": {
        title: "Choose an avatar for your agent",
        button: "Generate",
    },
    "es-ES": {
        title: "Elige un avatar para tu agente",
        button: "Generar",
    },
  };
  