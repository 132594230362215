export const LandingPageI18n = {
  "pt-BR": {
    button: "Abrir App",
    nav: {
      about: "Sobre",
      docs: "Documentos",
      capabilities: "Capacidades",
    },
    sectionOne: {
      badge: "Versão 2.0",
      title: "Seu prompt continua sendo seu",
      description:
        "Plataforma de IA que melhora a produtividade e eficiência com um forte foco em privacidade",
    },
    sectionTwo: {
      badge: "Sobre",
      title: "IA feita para todos, mas privada e sem permissões",
      description:
        "Converse anonimamente com inteligência artificial sem comprometer seus dados",
      cards: {
        robot:
          "Crie e treine agentes de IA personalizados sem necessidade de conhecimento técnico",
        chevron:
          "Aumente a produtividade mantendo sua propriedade intelectual segura",
        shield:
          "Proteja sua privacidade com anonimato impulsionado por IA em todas as suas tarefas",
        datas:
          "Armazene e gerencie seus dados com criptografia de ponta e garantias de privacidade",
        tecnology:
          "Armazene e acesse seus dados com segurança usando tecnologia criptografada e baseada em blockchain",
      },
    },
    sectionThree: {
      badge: "Capacidades",
      title: "Otimize suas operações com ferramentas avançadas",
      description:
        "Aumente a produtividade, mantenha a privacidade e otimize seu fluxo de trabalho com nossas ferramentas de IA",
      card1: {
        title: "Interaja com IA de forma privada",
        description:
          "Converse com modelos avançados de IA sem comprometer sua identidade ou dados pessoais",
        chat1: 'O que exatamente significa "sinergia" em trabalho em equipe?',
        chat2:
          '"Sinergia" no trabalho em equipe significa que os esforços combinados de um grupo produzem resultados maiores do que as contribuições individuais, impulsionando a colaboração e inovação.',
      },
      card2: {
        title: "Transforme ideias em visuais",
        description:
          "Gere imagens de alta qualidade usando IA, perfeito para redes sociais, marketing e muito mais.",
        chat: "Selfie de astronauta no espaço",
      },
      card3: {
        title: "Deixe a IA dominar seu site",
        description:
          "Forneça um link, e nossa IA analisará e entenderá o site, permitindo realizar tarefas, responder perguntas e oferecer insights detalhados de maneira integrada.",
        chat1:
          "Extraia as principais ideias deste artigo: www.aizzy.io/blog/...e-vcm",
        chat2: {
          header:
            "Aqui estão as principais ideias para o link de blog fornecido.",
          one: "Acessibilidade e Conveniência Aprimoradas:",
          dotOne:
            "Assistentes virtuais impulsionados por IA, como Siri, Alexa e Aizzy, estão se tornando essenciais, simplificando tarefas diárias como gestão de horários, lembretes e controle de dispositivos domésticos inteligentes.",
          two: "Revolucionando a Saúde:",
          dotTwo:
            "A IA está revolucionando diagnósticos e tratamentos ao analisar dados médicos com precisão inédita, ajudando médicos a tomarem decisões mais informadas.",
        },
      },
      card4: {
        title: "Chegue ao núcleo de seus documentos",
        description:
          "Resuma facilmente PDFs longos, extraindo os principais pontos para uma compreensão rápida.",
        chat: "O whitepaper apresenta o Bitcoin como uma solução para confiança e o duplo gasto em transações digitais, utilizando prova criptográfica em vez de uma autoridade central.",
      },
      card5: {
        title: "Desenhe seus próprios agentes de IA",
        description:
          "Construa e treine agentes de IA personalizados para gerenciar tarefas, responder a perguntas e processos de negócios.",
      },
    },
    sectionFour: {
      badge: "Recompensas",
      title: "Ganhe dinheiro com nosso programa de recompensas de afiliados",
      description:
        "Converse anonimamente com inteligência artificial sem comprometer seus dados",
      cardOne:
        "Promova nossa IA usando seu link de afiliado exclusivo e ganhe 10% de comissão para cada assinatura de sua indicação.",
      cardTwo:
        "Compartilhe seu link de afiliado nas redes sociais, blogs ou sites e receba 10% de cada assinatura realizada via seu link.",
      cardThree:
        "Aumente seus ganhos gerando mais assinaturas através de seu link. Você ganhará uma comissão de 10% em cada venda realizada.",
    },
    sectionFive: {
      title: "Experimente o futuro da produtividade e privacidade, hoje",
      description:
        "Descubra como nossas ferramentas de IA avançadas podem melhorar sua produtividade. Inscreva-se agora e comece a transformar seu fluxo de trabalho!",
    },
    footer: {
      description: "Redefinindo a IA com privacidade e eficiência.",
      explore: {
        title: "Explorar",
        github: "Github",
        aiz: "Token AIZ",
        base: "Basecan AIZ",
        uniswap: "Uniswap",
      },
      learnMore: {
        title: "Saiba Mais",
        about: "Sobre nós",
        docs: "Documentos",
        faqs: "FAQs",
      },
      legal: "Aviso Legal",
      disclaimer: "Isenção de responsabilidade",
    },
  },
  "en-US": {
    button: "Launch App",
    nav: {
      about: "About",
      docs: "Docs",
      capabilities: "Capabilities",
    },
    sectionOne: {
      badge: "Version 2.0",
      title: "Your prompt remains yours",
      description:
        "AI platform enhancing productivity and efficiency with a strong focus on privacy",
    },
    sectionTwo: {
      badge: "About",
      title: "AI made for everyone but private & permisionless",
      description:
        "Chat anonymously with artificial intelligence without compromising your data",
      cards: {
        robot:
          "Create and train custom AI agents without technical expertise required",
        chevron:
          "Enhance productivity while keeping your intellectual property safe",
        shield:
          "Protect your privacy with AI-powered anonymity in all your tasks",
        datas:
          "Store and handle your data with top-notch encryption and privacy safeguards",
        tecnology:
          "Securely store and access your data with encrypted, blockchain-based technology",
      },
    },
    sectionThree: {
      badge: "Capabilities",
      title: "Streamline your operations with advanced tools",
      description:
        "Enhance productivity, maintain privacy, and optimize your workflow efficiently with our AI tools",
      card1: {
        title: "Engage with AI privately",
        description:
          "Chat with advanced AI models without compromising your identity or personal data",
        chat1: 'What exactly does "synergy" mean in teamwork?',
        chat2:
          '"Synergy" in teamwork means that the combined efforts of a group produce greater results than individual contributions alone, dri ',
      },
      card2: {
        title: "Transform ideas into visuals",
        description:
          "Generate high-quality images using AI, perfect for social media, marketing, and more.",
        chat: "Astronaut selfie from space",
      },
      card3: {
        title: "Let AI master your website",
        description:
          "Provide a link, and our AI will analyze and understand the site, enabling it to perform tasks, answer questions, and offer detailed insights seamlessly.",
        chat1:
          "Extract key ideas from this article: www.aizzy.io/blog/...e-vcm",
        chat2: {
          header: "Here are the key ideas for a blog link you provided.",
          one: "Enhanced Accessibility and Convenience:",
          dotOne:
            "AI-powered virtual agents like Siri, Alexa, and Aizzy are becoming household staples, simplifying daily tasks like managing schedules, setting reminders, and controlling smart home devices.",
          two: "Revolutionizing Healthcare:",
          dotTwo:
            "AI is revolutionizing diagnostics and treatment plans by analyzing medical data with unprecedented accuracy, helping doctors make more informed decisions. Telemedicine and AI-powered health apps enable remote monitoring of patients, ensuring continuous care and early detection of potential health issues. Personalized Learning and Education: AI-driven educational platforms tailor learning experiences to individual needs, making education more personalized and effective.",
        },
      },
      card4: {
        title: "Get to the core of your documents",
        description:
          "Effortlessly summarize lengthy PDFs, extracting the key points for quick understanding.",
        chat: "The whitepaper presents Bitcoin as a solution to trust and double-spending in digital transactions, using cryptographic proof instead of relying on a central authority.",
      },
      card5: {
        title: "Design your own AI agents",
        description:
          "Build and train personalized AI agents to handle tasks, inquiries, and business processes.",
      },
    },
    sectionFour: {
      badge: "Rewards",
      title: "Earn money with our affiliate reward program",
      description:
        "Chat anonymously wih artificial intelligence without compromising your data",
      cardOne:
        "Promote our AI using your unique affiliate link and earn a 10% commission for each subscription from your referral link.",
      cardTwo:
        "Share your affiliate link on social media, blogs, or websites, and receive 10% of every subscription made via your link.",
      cardThree:
        "Increase your earnings by generating more subscriptions through your link. You’ll earn a 10% commission on every sale made.",
    },
    sectionFive: {
      title: "Experience the future of productivity and privacy, today",
      description:
        "Discover how our advanced AI tools can enhance your productivity. Sign up now and start transforming your workflow!",
    },
    footer: {
      description: "Redefining AI with bold privacy and efficiency.",
      explore: {
        title: "Explore",
        github: "Github",
        aiz: "AIZ Token",
        base: "AIZ Basecan",
        uniswap: "Uniswap",
      },
      learnMore: {
        title: "Learn More",
        about: "About us",
        docs: "Docs",
        faqs: "FAQs",
      },
      legal: "Disclaimer",
      disclaimer: "Disclaimer",
    },
  },
  "es-ES": {
    button: "Iniciar Aplicación",
    nav: {
      about: "Sobre",
      docs: "Documentos",
      capabilities: "Capacidades",
    },
    sectionOne: {
      badge: "Versión 2.0",
      title: "Tu mensaje sigue siendo tuyo",
      description:
        "Plataforma de IA que mejora la productividad y eficiencia con un fuerte enfoque en privacidad",
    },
    sectionTwo: {
      badge: "Acerca de",
      title: "IA hecha para todos, pero privada y sin permisos",
      description:
        "Chatea anónimamente con inteligencia artificial sin comprometer tus datos",
      cards: {
        robot:
          "Crea y entrena agentes de IA personalizados sin necesidad de experiencia técnica",
        chevron:
          "Aumenta la productividad manteniendo segura tu propiedad intelectual",
        shield:
          "Protege tu privacidad con anonimato impulsado por IA en todas tus tareas",
        datas:
          "Almacena y gestiona tus datos con cifrado de primer nivel y salvaguardias de privacidad",
        tecnology:
          "Almacena y accede a tus datos de forma segura con tecnología cifrada y basada en blockchain",
      },
    },
    sectionThree: {
      badge: "Capacidades",
      title: "Optimiza tus operaciones con herramientas avanzadas",
      description:
        "Aumenta la productividad, mantén la privacidad y optimiza tu flujo de trabajo de manera eficiente con nuestras herramientas de IA",
      card1: {
        title: "Interactúa con IA de forma privada",
        description:
          "Chatea con modelos avanzados de IA sin comprometer tu identidad o datos personales",
        chat1: '¿Qué significa exactamente "sinergia" en el trabajo en equipo?',
        chat2:
          '"Sinergia" en el trabajo en equipo significa que los esfuerzos combinados de un grupo producen mayores resultados que las contribuciones individuales, fomentando la colaboración e innovación.',
      },
      card2: {
        title: "Transforma ideas en imágenes",
        description:
          "Genera imágenes de alta calidad usando IA, perfectas para redes sociales, marketing y más.",
        chat: "Selfie de astronauta desde el espacio",
      },
      card3: {
        title: "Permite que la IA domine tu sitio web",
        description:
          "Proporciona un enlace, y nuestra IA analizará y comprenderá el sitio, permitiéndole realizar tareas, responder preguntas y ofrecer ideas detalladas de forma integrada.",
        chat1:
          "Extrae las ideas principales de este artículo: www.aizzy.io/blog/...e-vcm",
        chat2: {
          header:
            "Aquí están las ideas principales para el enlace de blog proporcionado.",
          one: "Mayor Accesibilidad y Conveniencia:",
          dotOne:
            "Los asistentes virtuales impulsados por IA, como Siri, Alexa y Aizzy, se están convirtiendo en elementos básicos, simplificando tareas diarias como gestión de horarios, recordatorios y control de dispositivos domésticos inteligentes.",
          two: "Revolucionando la Atención Médica:",
          dotTwo:
            "La IA está revolucionando los diagnósticos y tratamientos al analizar datos médicos con precisión sin precedentes, ayudando a los médicos a tomar decisiones más informadas.",
        },
      },
      card4: {
        title: "Llega al núcleo de tus documentos",
        description:
          "Resume fácilmente documentos largos, extrayendo los puntos clave para una comprensión rápida.",
        chat: "El libro blanco presenta Bitcoin como una solución a la confianza y al doble gasto en transacciones digitales, utilizando prueba criptográfica en lugar de una autoridad central.",
      },
      card5: {
        title: "Diseña tus propios asistentes de IA",
        description:
          "Construye y entrena asistentes de IA personalizados para gestionar tareas, responder consultas y procesos de negocios.",
      },
    },
    sectionFour: {
      badge: "Recompensas",
      title: "Gana dinero con nuestro programa de recompensas de afiliados",
      description:
        "Chatea anónimamente con inteligencia artificial sin comprometer tus datos",
      cardOne:
        "Promociona nuestra IA usando tu enlace de afiliado único y gana una comisión del 10% por cada suscripción de tu enlace.",
      cardTwo:
        "Comparte tu enlace de afiliado en redes sociales, blogs o sitios web, y recibe el 10% de cada suscripción realizada a través de tu enlace.",
      cardThree:
        "Incrementa tus ingresos generando más suscripciones a través de tu enlace. Ganarás una comisión del 10% en cada venta realizada.",
    },
    sectionFive: {
      title: "Experimenta el futuro de la productividad y privacidad, hoy",
      description:
        "Descubre cómo nuestras herramientas avanzadas de IA pueden mejorar tu productividad. ¡Regístrate ahora y comienza a transformar tu flujo de trabajo!",
    },
    footer: {
      description: "Redefiniendo la IA con privacidad y eficiencia.",
      explore: {
        title: "Explorar",
        github: "Github",
        aiz: "Token AIZ",
        base: "Basecan AIZ",
        uniswap: "Uniswap",
      },
      learnMore: {
        title: "Aprender Más",
        about: "Sobre nosotros",
        docs: "Documentación",
        faqs: "FAQs",
      },
      legal: "Aviso Legal",
      disclaimer: "Descargo de responsabilidad",
    },
  },
};
