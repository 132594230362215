import ButtonType1 from "../../Buttons/ButtonType1";
import { useState } from "react";
import iconPhantom from "../../../assets/images/icons/phantom.png";
import ModalInstallPhantom from "../../Modals/ModalInstallPhantom";
import { setOpenLogin } from "../../../redux/general/action";
import { useDispatch } from "react-redux";
import bs58 from "bs58";
import { requestChallengePhantom, verifyChallengePhantom } from "../../../api";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { showErrorNotification } from "../../../services/notifications";

const PhantomLogin = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [showModalInstallPhantom, setShowModalInstallPhantom] = useState(false);

  const supabaseClient = useSupabaseClient();

  const isPhantomInstalled = () => {
    return window.solana && window.solana.isPhantom;
  };

  async function connectPhantomWallet() {
    if (!isPhantomInstalled()) {
      setShowModalInstallPhantom(true);
      return;
    }

    try {
      setIsLoading(true);

      const resp = await window.solana.connect();
      const walletAddress = resp.publicKey.toBase58();

      const challengeResponse = await requestChallengePhantom(walletAddress);

      if (!challengeResponse?.success) {
        throw new Error(
          challengeResponse?.data || "Failed to obtain challenge from server"
        );
      }

      const challenge = challengeResponse.data;

      const encodedMessage = new TextEncoder().encode(challenge);
      const signedMessage = await window.solana.signMessage(
        encodedMessage,
        "utf8"
      );
      const signatureBase58 = bs58.encode(signedMessage.signature);

      const verifyResponse = await verifyChallengePhantom({
        address: walletAddress,
        message: challenge,
        signature: signatureBase58,
      });

      if (!verifyResponse?.access_token) {
        throw new Error(
          verifyResponse?.error || "Failed to verify signature on server"
        );
      }

      await supabaseClient.auth.signInWithPassword({
        email: verifyResponse.user.email,
        password: walletAddress,
        options: {
          redirectTo: `${process.env.REACT_APP_PUBLIC_SITE_URL}`,
        },
      });

      dispatch(setOpenLogin(false));
    } catch (error) {
      console.error("Erro ao conectar à Phantom Wallet:", error.message);
      showErrorNotification(
        "Erro ao conectar à Phantom Wallet: " + error.message
      );
    } finally {
      setIsLoading(false);
    }
  }

  function disconnectPhantomWallet() {
    try {
      window.solana.disconnect();
      console.log("Carteira desconectada com sucesso!");
    } catch (error) {
      console.error("Erro ao desconectar a carteira:", error);
    }
  }

  return (
    <div>
      <ButtonType1
        icon={iconPhantom}
        text={"Entrar com Phantom"}
        variation="thirty"
        isLoading={isLoading}
        props={{
          className: "btn-socials w-100 mt-3",
          type: "button",
          style: { borderRadius: 8, padding: "13px 20px" },
          onClick: connectPhantomWallet,
        }}
      />

      <ModalInstallPhantom
        onclick={() => setShowModalInstallPhantom(false)}
        modalInstallMetamask={showModalInstallPhantom}
        iconMetaMask={iconPhantom}
      />
    </div>
  );
};

export default PhantomLogin;
