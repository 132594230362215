import React from "react";
import COLORS from "../../utils/constants/colors";

const splitTextByWords = (text, numLastWords) => {
  if (!text) return { initialWords: "", lastWords: "" };

  const words = text.split(" ");
  const lastWords = words.slice(-numLastWords).join(" ");
  const initialWords = words.slice(0, -numLastWords).join(" ");

  return { initialWords, lastWords };
};

const TitleColorsLanding = ({ title, numLastWords, style, className }) => {
  const { initialWords, lastWords } = splitTextByWords(title, numLastWords);

  return (
    <h1 className={`m-0 w-100 ${className}`} style={{ ...style }}>
      {initialWords} <span style={{ color: COLORS.gray400, ...style }} className={className}>{lastWords}</span>
    </h1>
  );
};

export default TitleColorsLanding;
