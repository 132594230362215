/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { IconEdit } from "@tabler/icons-react";
import ModalGlobal from "./ModalGlobal";
import { Select } from "antd";
import IconArrowDown from "../../assets/images/icons-new-layout/arrow-down.svg";
import ButtonType1 from "../Buttons/ButtonType1";
import { useEffect, useState } from "react";
import { createAssistant, getCommunityAssistant } from "../../api/assistants";
import { QUERY_KEYS, queryClient } from "../../libs/ReactQuery";
import { useQuery } from "react-query";
import UseTranslation from "../../hooks/useTranslation";
import { ModalCreateAssistantsI18n } from "./ModalCreateAssistantsNewLayout/ModalCreateAssistants.i18n";
import { showRealoadNotification } from "../../services/notifications";
import ButtonCloseModal from "../Buttons/ButtonCloseModal";

const ModalEditAssistant = ({
  selectedAssistant,
  setOpenModal,
  handleGenerateImage,
  setModalEditImage,
  openModal,
  avatarSelected,
  handleSaveData,
  handleEditName,
  handleEditRole,
}) => {
  let showError = false;
  const [name, setName] = useState("");
  const [userName, setUserName] = useState("");
  const [about, setAbout] = useState("");
  const [role, setRole] = useState("");
  const [category, setCategory] = useState("");
  const [enabled, setEnabled] = useState("");
  const [isPrivate, setIsPrivate] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [prompt, setPrompt] = useState("");
  const [defaultGreetings, setDefaultGreetings] = useState("");
  const [language, setLanguage] = useState("");
  const [knowledge, setKnowledge] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { t } = UseTranslation(ModalCreateAssistantsI18n);

  const { data: assistant, error } = useQuery(
    [QUERY_KEYS.EDIT_ASSISTANT, selectedAssistant?._id],
    async () => {
      return await getCommunityAssistant(selectedAssistant?._id);
    },
    {
      enabled: !!selectedAssistant?._id,
      keepPreviousData: true,
    }
  );

  const selectPrivate = value => {
    if (value === "Privado") {
      setIsPrivate(true);
    } else if (value === "Público") {
      setIsPrivate(false);
    }
  };

  const handleActiveChange = value => {
    if (value === "Sim") {
      setEnabled(true);
    } else if (value === "Não") {
      setEnabled(false);
    }
  };

  // const handleCategoryChange = value => {
  //   setCategory(value);
  // };

  const handleLanguageChange = value => {
    setLanguage(value);
  };

  const resetValue = () => {
    setName(assistant?.name || "");
    setAbout(assistant?.about || "");
    setRole(assistant?.role || "");
    setCategory(assistant?.category || "");
    setEnabled(assistant?.enabled || "");
    setIsPrivate(assistant?.private || "");
    setProfileImage(assistant?.profileImage || "");
    setPrompt(assistant?.prompt || "");
    setDefaultGreetings(assistant?.defaultGreetings || "");
    setLanguage(assistant?.language || "");
    setKnowledge(assistant?.knowledge || "");
    setOpenModal(false);
  };

  useEffect(() => {
    const newAssistant = JSON.parse(localStorage.getItem("newAssistant"));

    if (newAssistant && newAssistant._id === selectedAssistant?._id) {
      setName(newAssistant.name);
      setAbout(newAssistant.about);
      setRole(newAssistant.role);
      setCategory(newAssistant.category);
      setEnabled(newAssistant.enabled);
      setIsPrivate(newAssistant.private);
      setProfileImage(newAssistant.profileImage);
      setPrompt(newAssistant.prompt);
      setDefaultGreetings(newAssistant.defaultGreetings);
      setLanguage(newAssistant.language);
      setKnowledge(newAssistant.knowledge);
    } else if (assistant) {
      setName(assistant.name);
      setAbout(assistant.about);
      setRole(assistant.role);
      setCategory(assistant.category);
      setEnabled(assistant.enabled);
      setIsPrivate(assistant.private);
      setProfileImage(assistant.profileImage);
      setPrompt(assistant.prompt);
      setDefaultGreetings(assistant.defaultGreetings);
      setLanguage(assistant.language);
      setKnowledge(assistant.knowledge);
    }
  }, [assistant, selectedAssistant]);

  const dataNewAssistants = {
    languages: [],
    category: category,
    private: isPrivate,
    enabled: enabled,
    defaultGreetings: defaultGreetings,
    profileImage: profileImage,
    about: about,
    prompt: prompt,
    role: role,
    language: language,
    name: name,
    _id: selectedAssistant?._id,
  };

  const handleUpdateAssistant = async () => {
    try {
      setIsLoading(true);
      const response = await createAssistant(dataNewAssistants);
      setOpenModal(false);
      setIsLoading(false);
      await queryClient.invalidateQueries(QUERY_KEYS.MY_ASSISTANTS);
    } catch (error) {
      console.log(error);
      setOpenModal(false);
    }
  };

  const checkIfFormIsDirty = () => {
    return (
      name !== assistant?.name ||
      about !== assistant?.about ||
      role !== assistant?.role ||
      category !== assistant?.category ||
      enabled !== assistant?.enabled ||
      isPrivate !== assistant?.private ||
      profileImage !== assistant?.profileImage ||
      prompt !== assistant?.prompt ||
      defaultGreetings !== assistant?.defaultGreetings ||
      language !== assistant?.language
    );
  };

  useEffect(() => {
    handleEditName(name);
  }, [name, selectedAssistant, handleEditName]);

  useEffect(() => {
    if (role !== selectedAssistant?.role) {
      handleEditRole(role);
    }
  }, [role, selectedAssistant, handleEditRole]);

  useEffect(() => {
    if (avatarSelected) {
      setProfileImage(avatarSelected);
    }
  }, [avatarSelected]);

  useEffect(() => {
    showRealoadNotification(error ? error?.message : null, showError);
    showError = true;
  }, [error]);

  return (
    <ModalGlobal
      open={openModal}
      classNameChildren={`modal-create-assistants justify-content-between animate__animated animate__fadeInDown`}
      onClick={() => {
        setOpenModal(false);
      }}
    >
      <div className="w-100 d-flex flex-column" style={{ gap: "32px" }}>
        <div className="w-100 d-flex justify-content-between align-items-center">
          <h4 className="m-0">Modal edit</h4>
          <ButtonCloseModal
            onClick={() => {
              setOpenModal(false);
              resetValue();
            }}
          />
        </div>
        <div className="review-publish-assistants w-100">
          <div className="create-assistants-general-info">
            <div className="d-flex w-100 justify-content-between align-items-center">
              <span className="span">{t("tabs.review.inputs.name.label")}</span>
              <div className="div-input-general-info">
                <input
                  type="text"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  maxLength={30}
                />
              </div>
            </div>
            <div className="d-flex w-100 justify-content-between align-items-center">
              <span className="span">
                {t("tabs.review.inputs.about.label")}
              </span>
              <div className="div-input-general-info">
                <input value={about} onChange={e => setAbout(e.target.value)} />
              </div>
            </div>
            <div className="d-flex w-100 justify-content-between align-items-center">
              <span className="span">{t("tabs.review.inputs.role.label")}</span>
              <div className="div-input-general-info">
                <input
                  type="text"
                  value={role}
                  onChange={e => setRole(e.target.value)}
                />
              </div>
            </div>
            <div className="d-flex w-100 justify-content-between align-items-center">
              <span className="span">
                {t("tabs.review.inputs.active.label")}
              </span>
              <Select
                className="select-antd-create-assistant"
                suffixIcon={
                  <img
                    src={IconArrowDown}
                    height={10}
                    width={10}
                    alt="Arrow down"
                  />
                }
                size="large"
                showSearch
                filterOption={false}
                value={
                  enabled === "" ? "Select" : enabled === true ? "Sim" : "Não"
                }
                onChange={handleActiveChange}
              >
                <Select.Option
                  style={{ display: "flex", alignItems: "center" }}
                  value="Sim"
                >
                  Sim
                </Select.Option>
                <Select.Option
                  style={{ display: "flex", alignItems: "center" }}
                  value="Não"
                >
                  Não
                </Select.Option>
              </Select>
            </div>
            <div className="d-flex w-100 justify-content-between align-items-center">
              <span className="span">
                {t("tabs.review.inputs.control.label")}
              </span>
              <Select
                className="select-antd-create-assistant"
                suffixIcon={
                  <img
                    src={IconArrowDown}
                    height={10}
                    width={10}
                    alt="Arrow down"
                  />
                }
                size="large"
                showSearch
                filterOption={false}
                value={
                  isPrivate === ""
                    ? "Select"
                    : isPrivate === true
                    ? "Privado"
                    : "Público"
                }
                onChange={selectPrivate}
              >
                <Select.Option
                  style={{ display: "flex", alignItems: "center" }}
                  value="Público"
                >
                  {t("tabs.general.inputs.control.placeholder.public")}
                </Select.Option>
                <Select.Option
                  style={{ display: "flex", alignItems: "center" }}
                  value="Privado"
                >
                  {t("tabs.general.inputs.control.placeholder.private")}
                </Select.Option>
              </Select>
            </div>
            <div className="d-flex w-100 justify-content-between align-items-start">
              <span className="span">
                {t("tabs.review.inputs.avatar.label")}
              </span>
              <div className="select-avatar w-100 d-flex flex-column justify-content-start gap-2">
                <div
                  style={{ width: 110, height: 40 }}
                  className="d-flex justify-content-center align-items-center div-input-upload-avatar"
                >
                  <div className="d-flex justify-content-between align-items-center w-100 px-2">
                    <img
                      src={profileImage}
                      alt=""
                      width={24}
                      height={24}
                      style={{ borderRadius: "50%" }}
                    />
                    <IconEdit
                      size={14}
                      stroke={1.5}
                      cursor={"pointer"}
                      onClick={() => {
                        setModalEditImage(true);
                        handleGenerateImage();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="create-assistants-data">
            <div className="d-flex w-100 justify-content-between align-items-start">
              <span className="span">
                {t("tabs.review.inputs.prompt.label")}
              </span>
              <div className="textarea-data">
                <textarea
                  value={prompt}
                  onChange={e => setPrompt(e.target.value)}
                />
              </div>
            </div>
            <div className="d-flex w-100 justify-content-between align-items-center">
              <span className="span">
                {t("tabs.review.inputs.language.label")}
              </span>
              <Select
                className="select-antd-create-assistant"
                suffixIcon={
                  <img
                    src={IconArrowDown}
                    height={10}
                    width={10}
                    alt="Arrow down"
                  />
                }
                size="large"
                showSearch
                filterOption={false}
                value={
                  language === ""
                    ? "Select language"
                    : language === "pt"
                    ? "Português"
                    : language === "es"
                    ? "Spanish"
                    : "English"
                }
                onChange={handleLanguageChange}
                placeholder="Select language"
              >
                <Select.Option value="English">English</Select.Option>
                <Select.Option value="Spanish">Spanish</Select.Option>
                <Select.Option value="Português">Português</Select.Option>
              </Select>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center gap-3">
        <ButtonType1
          props={{
            style: { padding: "8px 12px", borderRadius: 8 },
            type: "primary",
            onClick: () => {
              setOpenModal(false);
              resetValue();
            },
          }}
          text={t("buttons.cancel")}
        />
        <ButtonType1
          props={{
            style: { padding: "8px 12px", borderRadius: 8 },
            type: "primary",
            onClick: () => {
              handleUpdateAssistant();
              handleSaveData();
            },
            disabled: isLoading || !checkIfFormIsDirty(),
          }}
          text={t("buttons.edit")}
        />
      </div>
    </ModalGlobal>
  );
};

export default ModalEditAssistant;
