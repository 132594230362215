import { QUERY_KEYS } from "../libs/ReactQuery";
import { useGetIsLogged } from "./smallHooks";
import { useQuery } from "react-query";
import { getUserCredits, getUserSubscription } from "../api/creditPayments";
import { getUser } from "../services/userHelper";

const UseUserCredits = () => {
  const user = getUser();
  const isLogged = useGetIsLogged();

  const { data: credits, isLoading: isLoadingCredits } = useQuery(
    QUERY_KEYS.USER_CREDITS,
    getUserCredits,
    {
      enabled: isLogged,
      refetchOnWindowFocus: false,
    }
  );

  const { data: subscription, isLoading: isLoadingSubscription } = useQuery(
    QUERY_KEYS.USER_SUBSCRIPTION,
    getUserSubscription,
    {
      enabled: isLogged,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );

  const isEthereumOrSolanaEmail = (email: string) => {
    if (email && (email.includes("@erc20") || email.includes("@solana"))) {
      return true;
    }

    return false;
  };

  const hasSufficientCredits = () => {
    if (isEthereumOrSolanaEmail(user?.email!)) {
      return true;
    }

    const isActiveSubscription =
      subscription &&
      subscription.current_period_end > new Date().toISOString();

    if (isActiveSubscription) {
      return true;
    }

    if (!credits?.amount || credits.amount <= 0) {
      return false;
    }

    if (credits.amount < 1) {
      return false;
    }

    return true;
  };

  const hasSubscriptionActive = () => {
    const isActive =
      subscription &&
      subscription.current_period_end > new Date().toISOString();

    return isActive;
  };

  return {
    isLoadingCredits,
    credits,
    subscription,
    isLoadingSubscription,
    hasSufficientCredits,
    hasSubscriptionActive,
  };
};

export default UseUserCredits;
