import { useDispatch, useSelector } from "react-redux";
import MenuTopBar from "../../components/Menus/MenuTopBar";
import { IconRobotFace, IconUser } from "@tabler/icons-react";
import HeaderPageAssistants from "../../components/PagesComponents/PageAssistants/HeaderPageAssistants/HeaderPageAssistants";
import ModalGlobal from "../../components/Modals/ModalGlobal";
import { useEffect, useState } from "react";
import ButtonType1 from "../../components/Buttons/ButtonType1";
import { updateAssistantData } from "../../redux/createAssistants/action";
import { createAssistant } from "../../api/assistants";
import { useUser } from "@supabase/auth-helpers-react";
import GroupLogin from "../../components/Login/GroupLogin";
import CreateAssistantsGeneralInfo from "../../components/Modals/ModalCreateAssistantsNewLayout/CreateAssistantsGeneralInfo";
import CreateAssistantData from "../../components/Modals/ModalCreateAssistantsNewLayout/CreateAssistantData";
import ReviewPublishAssistants from "../../components/Modals/ModalCreateAssistantsNewLayout/ReviewPublishAssistants";
import { QUERY_KEYS, queryClient } from "../../libs/ReactQuery";
import { showErrorNotification } from "../../services/notifications";
import UseTranslation from "../../hooks/useTranslation";
import { ModalCreateAssistantsI18n } from "../../components/Modals/ModalCreateAssistantsNewLayout/ModalCreateAssistants.i18n";
import { toast } from "sonner";
import ButtonCloseModal from "../../components/Buttons/ButtonCloseModal";

const PageAssistants = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const [openModal, setOpenModal] = useState(false);
  const [isCreateAssistant, setIsCreateAssistant] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const assistantData = useSelector(
    state => state.assistantReducer.assistantData
  );

  const { t } = UseTranslation(ModalCreateAssistantsI18n);

  const user = useUser();
  const dataUser = user?.user_metadata;

  const { openLeftBar, openLogin } = state?.configsGeneralReducer || {};

  const tabs = [
    {
      id: "general",
      label: `1. ${t("tabs.general.title")}`,
      content: <CreateAssistantsGeneralInfo />,
    },
    {
      id: "data",
      label: `2. ${t("tabs.data.title")}`,
      content: <CreateAssistantData />,
    },
    {
      id: "final",
      label: `3. ${t("tabs.review.title")}`,
      content: <ReviewPublishAssistants />,
    },
  ];

  const handleCancel = () => {
    if (
      assistantData.name ||
      assistantData.about ||
      assistantData.role ||
      assistantData.avatar ||
      assistantData.enabled ||
      assistantData.private === false ||
      assistantData.private === true
    ) {
      toast(t("notification.cancel"), {
        action: {
          label: t("buttons.cancel"),
          onClick: () => {
            setOpenModal(false);
            handleResetAssistantData();
            setSelectedTab(0);
          },
        },
        className: "toast-no-creditis",
      });
    } else {
      setOpenModal(false);
      handleResetAssistantData();
    }
  };

  const handleTabNext = () => {
    if (selectedTab === 0) {
      const {
        name,
        about,
        role,
        avatar,
        enabled,
        private: isPrivate,
      } = assistantData;

      const isAnyFieldEmpty =
        !name.trim() ||
        !about.trim() ||
        !role.trim() ||
        !avatar ||
        enabled === undefined ||
        isPrivate === undefined;

      if (isAnyFieldEmpty) {
        showErrorNotification(t("notification.verify"));
        return;
      }
    }

    if (selectedTab === 1) {
      const { prompt, language } = assistantData;

      const isAnyFieldEmptyForTab1 = !prompt.trim() || !language.trim();

      if (isAnyFieldEmptyForTab1) {
        showErrorNotification(t("notification.verify"));
        return;
      }
    }

    if (selectedTab < tabs.length - 1) {
      setSelectedTab(selectedTab + 1);
    }
  };

  const handleTabPreviuos = () => {
    if (selectedTab > 0) {
      setSelectedTab(selectedTab - 1);
    }
  };

  const handleResetAssistantData = () => {
    dispatch(
      updateAssistantData({
        name: "",
        about: "",
        role: "",
        category: "",
        prompt: "",
        language: "",
        knowledge: "",
        avatar: null,
        private: undefined,
        enabled: undefined,
      })
    );
  };

  const languageAbbreviation =
    assistantData.language === "Português"
      ? "pt"
      : assistantData.language === "English"
      ? "en"
      : "es";

  const dataNewAssistants = {
    languages: [],
    category: "Assistant",
    private: assistantData.private,
    enabled: assistantData.enabled,
    defaultGreetings: assistantData.defaultGreetings,
    profileImage: assistantData.avatar,
    about: assistantData.about,
    prompt: assistantData.prompt,
    role: assistantData.role,
    language: languageAbbreviation,
    name: assistantData.name,
    userName: dataUser?.name ?? assistantData.name,
  };

  const handleCreateAssistant = async () => {
    try {
      setIsCreateAssistant(true);
      await createAssistant(dataNewAssistants);
      await queryClient.invalidateQueries(QUERY_KEYS.MY_ASSISTANTS);
      setOpenModal(false);
      setSelectedTab(0);
      handleResetAssistantData();
      const createNewAssistant = {
        ...dataNewAssistants,
        timestamp: Date.now(),
      };

      localStorage.setItem("newAssistant", JSON.stringify(createNewAssistant));
    } catch (error) {
      showErrorNotification(error.message || "Error creating assistant");
    } finally {
      setIsCreateAssistant(false);
    }
  };

  const checkAndRemoveExpiredAssistant = () => {
    const savedAssistant = JSON.parse(localStorage.getItem("newAssistant"));

    if (savedAssistant) {
      const currentTime = Date.now();
      const expirationTime = 10 * 60 * 1000;

      if (currentTime - savedAssistant.timestamp > expirationTime) {
        localStorage.removeItem("newAssistant");
      }
    }
  };

  useEffect(() => {
    checkAndRemoveExpiredAssistant();
  }, []);

  return (
    <section className={`page-assistants ${!openLeftBar ? "maximize" : ""}`}>
      <MenuTopBar publishAssistant={true} onClick={() => setOpenModal(true)} />
      <HeaderPageAssistants onClick={() => setOpenModal(true)} />
      <ModalGlobal
        onClick={() => {
          setSelectedTab(0);
          handleResetAssistantData();
        }}
        open={openModal}
        classNameChildren={`modal-create-assistants justify-content-between animate__animated animate__fadeInDown animate__faster`}
      >
        <div
          className="d-flex flex-column align-items-center justify-content-between w-100"
          style={{ gap: "32px" }}
        >
          <div className="d-flex align-items-center justify-content-between w-100">
            <div className="d-flex gap-2 align-items-center">
              <div className="btn-icon-header d-flex align-items-center justify-content-center">
                <IconRobotFace stroke={1.5} style={{ width: 16, height: 16 }} />
              </div>
              <h3 className="m-0">{t("title")}</h3>
            </div>
            <ButtonCloseModal onClick={handleCancel} />
          </div>

          <div className="w-100 d-flex justify-content-between gap-3">
            {tabs?.map((stepper, index) => {
              return (
                <div
                  key={stepper?.id}
                  className="w-100 d-flex flex-column gap-2"
                >
                  <div
                    className={`stepper-bar
                   ${selectedTab >= index ? "active" : ""}`}
                  ></div>
                  <span
                    className={`stepper-label
                   ${selectedTab >= index ? "active" : ""}`}
                  >
                    {stepper?.label}
                  </span>
                </div>
              );
            })}
          </div>
          <div className="w-100">{tabs[selectedTab].content}</div>
        </div>

        {tabs[selectedTab] && (
          <div className="d-flex justify-content-between gap-2 w-100">
            <div className="d-flex align-items-center gap-2">
              <span style={{ marginRight: 5 }}>Publishing as</span>
              {dataUser?.picture ? (
                <img
                  src={dataUser?.picture}
                  alt=""
                  className="profile-user"
                  style={{
                    width: 20,
                    height: 20,
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <IconUser size={20} />
              )}
              <span>{dataUser?.name}</span>
            </div>
            <div className="d-flex" style={{ gap: 12 }}>
              {selectedTab === 0 ? (
                <ButtonType1
                  text={t("buttons.cancel")}
                  props={{
                    style: { padding: "10px 16px", borderRadius: 10 },
                    onClick: () => {
                      handleCancel();
                    },
                  }}
                />
              ) : (
                <ButtonType1
                  text={t("buttons.previous")}
                  props={{
                    style: { padding: "10px 16px", borderRadius: 10 },
                    onClick: handleTabPreviuos,
                  }}
                />
              )}
              {selectedTab === 2 ? (
                <ButtonType1
                  text={t("buttons.create")}
                  isLoading={isCreateAssistant}
                  props={{
                    style: { padding: "9px 16px", borderRadius: 10 },
                    onClick: async () => {
                      await handleCreateAssistant();
                    },
                  }}
                />
              ) : (
                <ButtonType1
                  text={t("buttons.next")}
                  variation="primary"
                  props={{
                    style: { padding: "9px 16px", borderRadius: 10 },
                    onClick: handleTabNext,
                  }}
                />
              )}
            </div>
          </div>
        )}
      </ModalGlobal>
      <GroupLogin isShow={openLogin} />
    </section>
  );
};

export default PageAssistants;
