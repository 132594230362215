import { useSelector } from "react-redux";
import COLORS from "../../utils/constants/colors";
import BREAKPOINTS from "../../utils/constants/breakpoints";

const CardRoadmapType1 = ({ img, text, bottom, left, right, top, className, maxWidth, style }) => {
  const state = useSelector(state => state);

  const breakpointLg =
    state?.configsGeneralReducer?.windowWidth < BREAKPOINTS.LG;

  return (
    <div
      className={`w-100 d-flex justify-content-start align-items-center ${className}`}
      style={{
        background: COLORS.white,
        borderRadius: 16,
        padding: breakpointLg ? "8px 16px" : 16,
        gap: 16,
        maxWidth: maxWidth,
        minHeight: breakpointLg ? 58 : 72,
        position: "absolute",
        bottom: bottom,
        left: left,
        right: right,
        top: top,
        zIndex: 2,
        boxShadow: `
           4px 4px 0px 0px rgba(16, 24, 40, 0.05)`,
        ...style,
      }}
    >
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          background: COLORS.gray900,
          minWidth: breakpointLg ? 32 : 40,
          minHeight: breakpointLg ? 32 : 40,
          borderRadius: breakpointLg ? 8 : 12,
          cursor: "default",
          color: COLORS.white,
        }}
      >
        <img src={img} alt="" />
      </div>
      <div
        className="m-0 p-0 two-lines w-100"
        style={{ color: COLORS.gray700, fontSize: breakpointLg ? 12 : 14 }}
      >
        {text}
      </div>
    </div>
  );
};

export default CardRoadmapType1;
